import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Input } from "@/common/components/ui/input";

import { EyeIcon, EyeOffIcon, LockIcon, MailIcon } from "lucide-react";
import { useState } from "react";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { LoginRequest, login } from "../api/login";
import { loginWithTokens } from "../utils";

export const formSchema = z.object({
    email: z.string().email(),
    password: z.string().min(1, {
        message: 'requird',
    }),
});

export function LoginForm() {
    const [error, setError] = useState<unknown>(null);
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (request: LoginRequest) => {
        const tokens = await login(request);
        await loginWithTokens(tokens);
    }

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: '',
            password: '',
        }
    });

    const handleSubmit = async (
        values: z.infer<typeof formSchema>,
    ): Promise<any> => {
        setError(null);
        setSubmitting(true);

        try {
            await handleLogin(values);
            navigate('/app', { replace: true, })
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} className="mb-4" />

            <div className="grid grid-cols-1 gap-8">
                <FormField
                    control={form.control}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        className='pl-10'
                                        placeholder="Email"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <MailIcon />
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name='password'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Password*</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        className='pl-10 pr-10'
                                        placeholder="Password"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <LockIcon />
                                    </div>
                                    <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowPassword(s => !s)
                                            }}
                                            size='iconSm'
                                            variant='ghost'>
                                            {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                        </Button>
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex justify-end">
                    <a
                        href='/auth/forgot-password'
                        className='italic text-sm text-foreground/50'>
                        Forgot Password?
                    </a>
                </div>
                <div className='text-center'>
                    <Button
                        disabled={submitting}
                        type="submit"
                        size='lg'
                        className='text-base font-normal px-16'>
                        Sign In
                    </Button>
                </div>
            </div>
        </form>
    </Form>
}