import { StateSelectFormControl } from "@/common/components/form-controls/state-select-form-control";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { cn } from "@/common/utils/cn";
import { asOptionalString } from "@/common/utils/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const addressFormSchema = z.object({

    addressLine1: z.string()
        .min(1, { message: 'Address is required' })
        .max(255, { message: 'Address can be max 255 characters' }),

    addressLine2: asOptionalString(z.string()
        .min(1)
        .max(255)),

    stateCode: z.string().min(2, { message: 'required' }),

    city: z.string().min(1, 'required'),

    zipCode: z.string().min(1, 'required'),
});

export type AddressFormSchemaType = z.infer<typeof addressFormSchema>;

// Make every property required because we cannot use undefined as value for controlled inputs
// Removing this would make allow undefined 
// which could lead to uncontrolled input being converted to controlled input.
export type AddressFormInitialValuesType = Required<AddressFormSchemaType>;

export function DealerAddressForm({
    initialValues,
    onSubmit,
    renderSubmitButton,
}: {
    initialValues?: AddressFormInitialValuesType,
    onSubmit: (values : AddressFormSchemaType) => any | Promise<any>,
    renderSubmitButton?: (submitting: boolean) => React.ReactElement
}) {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<AddressFormSchemaType>({
        resolver: zodResolver(addressFormSchema),
        defaultValues: initialValues,
    });

    const handleSubmit : SubmitHandler<AddressFormSchemaType> = async (values) => {
        try {
            setError(null);
            setSubmitting(true);
            await onSubmit(values);
        } catch(e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} className="mb-4" />
            <div className="grid grid-cols-2 gap-8">
                <div className="col-span-2">
                    <FormField
                        control={form.control}
                        name="addressLine1"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Address*</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="col-span-2">
                    <FormField
                        control={form.control}
                        name="addressLine2"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Address Line 2</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="col-span-2">
                    <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>City*</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="col-span-1">
                    <FormField
                        control={form.control}
                        name="stateCode"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>State*</FormLabel>
                                <FormControl>
                                    <StateSelectFormControl field={field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="col-span-1">
                    <FormField
                        control={form.control}
                        name="zipCode"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Zipcode*</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                 <div className={
                    cn(
                        "col-span-2", 
                        { "text-right": !renderSubmitButton}
                    )}
                >
                    {
                        !!renderSubmitButton
                            ? renderSubmitButton(submitting)
                            : <Button type="submit" size='lg' disabled={submitting}>
                                Submit
                            </Button>
                    }
                </div>
            </div>
        </form>
    </Form>
}