import { AssetType } from "@/common/enums";
import z from 'zod';

export type UploadedAssetBasic = {
    id: string;

    path: string;

    type: AssetType;

    url: string;

    mimetype: string | null;

    size: number;

    filename: string;
}

export type UploadedAssetsJoinBasic = {
    id: string;

    asset: UploadedAssetBasic;
}

export const uploadedAssetSchema = z.object({
    id: z.string(),
    type: z.nativeEnum(AssetType),
    url: z.string(),
    mimetype: z.string().nullable(),
    size: z.number(),
    filename: z.string(),
})