import { toast } from "sonner";
import { ProjectRequestForm, ProjectRequestFormSchemaType } from "../forms/project-request-form";
import { useNavigate } from 'react-router-dom';
import { CreateProjectRequest, adminCreateProjectRequest } from '../api';

export function AdminCreateProjectRequestPage() {
    const navigate = useNavigate();

    const handleSubmit = async (data: ProjectRequestFormSchemaType) => {
        const { files, address, ...rest } = data;
        
        // add country code to address
        const addressWithCountryCode = {
            ...address,
            countryCode: 'US'
        };

        // create project request
        const payload : CreateProjectRequest = { 
            ...rest,
            files: files.map(f => f.id),
            address: addressWithCountryCode,
        };
        await adminCreateProjectRequest(payload);

        // create project request
        toast.success("Project Request Created");
        navigate('/app/admin/project-requests');
    }

    return <div>
        <div className="bg-card rounded p-8">
            <h1 className="text-3xl font-bold">Create Project Request</h1>
            <div className="mt-5">
                <ProjectRequestForm
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    </div>;
}