import { buttonVariants } from "@/common/components/ui/button";
import { Link, useParams } from "react-router-dom";
import { cn } from "@/common/utils/cn";
import { ResetPasswordForm } from "../components/reset-password-form";

export function ResetPassword() {
    const { token } = useParams();

    return <div className="flex justify-center items-start lg:items-center mt-16">
        <div className="bg-card shadow-lg rounded-lg w-full max-w-lg my-8 px-2">
            <div className="mt-16 mb-14">
                {/* <div className='flex justify-center'>
                <img className='w-[300px] h-auto' src={LOGO_PATH} />
            </div> */}
                <h1 className="text-3xl text-center font-bold">
                    Reset Password
                </h1>
                <p className="mt-4 text-foreground/50 text-center">
                    Choose your new password.
                </p>
            </div>
            
            <div className="p-3 md:px-8">
                <ResetPasswordForm token={token || ''} />
            </div>
            <div className='text-center mt-0 my-8'>
                <Link
                    to="/auth/forgot-password"
                    className={
                        cn(
                            buttonVariants({ variant: 'link', className: 'text-base px-2' }),
                            "text-foreground/50"
                        )
                    }>
                    Resend Link
                </Link>
            </div>
                    
                    
        </div>
    </div>
}