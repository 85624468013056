import { Protected } from "@/auth/components/protected";
import { DealerApplicationRedirector } from "@/dealer/components/dealer-application/dealer-application-redirector";
import { Outlet } from "react-router-dom";
import { AppNav } from "../components/app-nav";

export function AppLayout() {
    return <Protected>
        <DealerApplicationRedirector>
            <AppLayoutInternal />
        </DealerApplicationRedirector>
    </Protected>
}

function AppLayoutInternal() {
    return <div>
        <AppNav />
        <div className="my-2 px-2 md:container md:my-8">
            <Outlet />
        </div>
    </div>
}