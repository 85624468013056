import { Tokens } from "../auth/api/login";

export function getAccessTokenExpiry(tokens: Tokens | null) {

    // no token
    let expiration = tokens?.accessTokenExpiryTs || null;
    if (expiration === null) {
        return null;
    }

    // invalid date
    let expirationDate = new Date(expiration * 1000);
    if (isNaN(expirationDate.getTime())) {
        return null;
    }

    return expirationDate;
}