import { Link } from "react-router-dom";
import { buttonVariants } from "@/common/components/ui/button";
import { ArrowRightIcon } from "lucide-react";

export function StatItem({
    title, value, url,
}: {
    title: string;
    value: string | number;
    url?: string;
}) {
    return <div className="bg-card rounded shadow p-4">
        <h3 className="">{title}</h3>
        <p className="font-bold mt-3 text-3xl">{value}</p>
        {url && (
            <div className="mt-2 text-right">
                <Link
                    to={url}
                    className={buttonVariants({
                        size: 'icon',
                        variant: 'outline',
                        className: '!rounded-full'
                    }
                    )}
                >
                    <ArrowRightIcon />
                </Link>
            </div>
        )}
    </div>;
}
