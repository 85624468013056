import { useSearchParams } from "react-router-dom";
import { Pagination, PaginationList, PaginationNext, PaginationPrev } from "react-unstyled-pagination";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { buttonVariants } from "@/common/components/ui/button";

export function TablePagination({
    page, perPage, total,
}: {
    page: number;
    perPage: number;
    total: number;
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    return <Pagination
        page={page}
        onPageChange={(page) => {
            const params = new URLSearchParams(searchParams);
            params.set('page', page.toString());
            setSearchParams(params);
        }}
        className="flex items-stretch gap-1"
        total={Math.ceil(total / perPage)}
    >
        <PaginationPrev
            className={buttonVariants({ variant: 'outline', size: 'sm' })}
        >
            <span className='flex'>
                <ChevronLeftIcon size={12} />
            </span>
        </PaginationPrev>
        <PaginationList
            className={buttonVariants({ variant: 'outline', size: 'sm' })} />
        <PaginationNext
            className={buttonVariants({ variant: 'outline', size: 'sm' })}
        >
            <span className='flex'>
                <ChevronRightIcon size={12} />
            </span>
        </PaginationNext>
    </Pagination>;
}
