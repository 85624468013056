import { DealerApplicationStatus, ProjectRequestStatus, ProjectStatus } from "@/common/enums";
import useSWR from "swr";
import { adminDashboard } from "../api";
import { StatItem } from "./stat-item";
import { StatsSkleton } from "./stats-skleton";

export function AdminDashboardStats() {
    const {
        isLoading, data,
    } = useSWR('/admin/dashboard', adminDashboard);

    if (isLoading || data === undefined) {
        return <StatsSkleton />;
    }

    return <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {data?.counts.dealers.approved && <div className="col-span-1">
            <StatItem
                title="Approved Dealers"
                value={data.counts.dealers.approved}
                url={`/app/admin/dealers?applicationStatus=${DealerApplicationStatus.Approved}`} />
        </div>}
        {data?.counts.dealers.submitted && <div className="col-span-1">
            <StatItem
                title="Dealers To Approve"
                value={data.counts.dealers.submitted}
                url={`/app/admin/dealers?applicationStatus=${DealerApplicationStatus.Submitted}`} />
        </div>}
        {data?.counts.projectRequests.pending && <div className="col-span-1">
            <StatItem
                title="Requests Pending"
                value={data.counts.projectRequests.pending}
                url={`/app/admin/project-requests?status=${ProjectRequestStatus.Pending}`} />
        </div>}
        {data?.counts.projects.in_progress && <div className="col-span-1">
            <StatItem
                title="Ongoing Projects"
                value={data.counts.projects.in_progress}
                url={`/app/admin/projects?status=${ProjectStatus.InProgress}`} />
        </div>}
        {data?.counts.projects.submitted && <div className="col-span-1">
            <StatItem
                title="Projects To Approve"
                value={data.counts.projects.submitted}
                url={`/app/admin/projects?status=${ProjectStatus.Submitted}`} />
        </div>}
        {data?.counts.projects.requires_resubmission && <div className="col-span-1">
            <StatItem
                title="Resubmission Required Projects"
                value={data.counts.projects.requires_resubmission}
                url={`/app/admin/projects?status=${ProjectStatus.RequiresResubmission}`} />
        </div>}
    </div>;
}
