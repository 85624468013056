import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Table, TableBody, TableCell, TableRow } from "@/common/components/ui/table";
import { ProjectRequestStatus, projectRequestStatusLabels } from "@/common/enums";
import { formatMoneyStr } from "@/common/utils/money";
import { Loader2Icon } from "lucide-react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { UploadedAssetsGrid } from "../../uploaded-assets/components/uploaded-assets-grid";
import { dealerFindProjectRequestById } from "../api";
import { AcceptRequestButton } from "../components/accept-request-button";
import { RejectRequestButton } from "../components/reject-request-button";
import { Address } from "@/address/components/address";
import { ProjectRequestStatusComponent } from "../components/project-request-status-component";

export function DealerViewProjectRequestPage() {
    const { id } = useParams();
    const {
        data: projectRequest,
        isLoading,
        error,
        mutate,
    } = useSWR(['/dealer/project-requests/', id], (key) => {
        const [_, id] = key;
        return dealerFindProjectRequestById(id);
    });

    const isPending = projectRequest?.status === ProjectRequestStatus.Pending;

    if (isLoading) {
        return <div>
            <div className="flex justify-center py-4">
                <Loader2Icon className="text-primary w-12 h-12 animate-spin" />
            </div>
        </div>
    }

    if (error) {
        return <div>
            <AppErrorMessage error={error} />
        </div>
    }

    return <div className="bg-card rounded p-8">
        <h1 className="text-3xl font-bold">
            {projectRequest?.title}
        </h1>
        <div className="mt-5">
            {
                projectRequest && (
                    <div>
                        <div className="grid grid-cols-2 gap-x-4 gap-y-8">
                            <div className="flex flex-col col-span-1 gap-2">
                                <span className="font-bold">Status</span>
                                <ProjectRequestStatusComponent 
                                    projectRequest={projectRequest}
                                />
                            </div>
                            <div className="flex flex-col col-span-1 gap-2">
                                <span className="font-bold">Price</span>
                                <span>{formatMoneyStr(projectRequest.price)}</span>
                            </div>
                            {
                                projectRequest.companyName && (
                                    <div className="flex flex-col col-span-1 gap-2">
                                        <span className="font-bold">Company</span>
                                        <span>{ projectRequest.companyName }</span>
                                    </div>
                                )
                            }
                            {
                                projectRequest.address && (
                                    <div className="flex flex-col col-span-1 gap-2">
                                        <span className="font-bold">Address</span>
                                        <span>
                                            <Address address={projectRequest.address} />
                                        </span>
                                    </div>
                                )
                            }
                            {
                                (projectRequest?.specs && projectRequest?.specs.length > 0) && <div className="flex flex-col col-span-2">
                                    <span className="font-bold">Specs</span>
                                    <div>
                                        <Table>
                                            <TableBody>
                                                {projectRequest?.specs.map((spec, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>{spec.key}</TableCell>
                                                        <TableCell>{spec.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            }
                            {
                                projectRequest?.details && <div className="flex flex-col col-span-2 gap-2">
                                    <span className="font-bold">Details</span>
                                    <div>
                                        {projectRequest.details}
                                    </div>
                                </div>
                            }
                            {
                                projectRequest?.contract && <div className="flex flex-col col-span-2 gap-2">
                                    <span className="font-bold">Contract</span>
                                    <div>
                                        {projectRequest.contract}
                                    </div>
                                </div>
                            }
                            {projectRequest.files && projectRequest.files.length > 0 && (
                                <div className="flex flex-col col-span-2 gap-2">
                                    <span className="font-bold">Files</span>
                                    <UploadedAssetsGrid assets={projectRequest.files.map(f => f.asset)} />
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
        {
            (isPending) && (
                <>
                    <hr className="my-8" />
                    <div className="flex justify-end gap-2">
                        <AcceptRequestButton
                            onDone={() => {
                                mutate();
                            }}
                            requestId={projectRequest.id} />
                        <RejectRequestButton
                            onDone={() => {
                                mutate();
                            }}
                            requestId={projectRequest.id} />
                    </div>
                </>
            )
        }
    </div>
}
