import React from "react";
import { ReactComponent as CompletedIcon } from '../../../common/images/illustrations/access_denied.svg';
import { Button } from "@/common/components/ui/button";

export function DealerApplicationRejected({ onReapply } : {  onReapply: () => any }) {
    return <div className="w-full p-8 flex justify-center items-center">
        <div className="flex flex-col items-center">
            <CompletedIcon className="w-auto h-[200px]" />
            <h3 className="mt-8 text-red-500 text-3xl text-center">
                Your Application is rejected
            </h3>
            <p className="mt-4 max-w-sm text-center text-lg inline-block">
                Dont worry you can reapply again. Your application would be reconsidered.
            </p>
            <Button size='lg' className="mt-4" onClick={() => onReapply()}>
                Reapply
            </Button>
        </div>
    </div>;
}
