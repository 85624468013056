import { toast } from "sonner";
import { ProjectRequestForm, ProjectRequestFormSchemaInitialValuesType, ProjectRequestFormSchemaType } from "../forms/project-request-form";
import { useNavigate, useParams } from 'react-router-dom';
import { CreateProjectRequest, adminEditProjectRequest, adminFindProjectRequestById } from '../api';
import useSWR from "swr";

export function AdminEditProjectRequestPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (data: ProjectRequestFormSchemaType) => {
        const { files, address, ...rest } = data;
        
        // add country code to address
        const addressWithCountryCode = {
            ...address,
            countryCode: 'US'
        };

        // create project request
        const payload : CreateProjectRequest = { 
            ...rest,
            files: files.map(f => f.id),
            address: addressWithCountryCode,
        };
        await adminEditProjectRequest(id, payload);

        // create project request
        toast.success("Project Request Edited");
        navigate('/app/admin/project-requests');
    }

    const { data: request } = useSWR(['/admin/project-requests', id], async (key) => {
        let { files, ...rest } = await adminFindProjectRequestById(key[1]);
        return {
            ...rest,
            files: files.map(f => f.asset),
        } as ProjectRequestFormSchemaInitialValuesType;
    });

    return <div>
        <div className="bg-card rounded p-8">
            <h1 className="text-3xl font-bold">Edit Project Request</h1>
            <div className="mt-5">
                {
                    request && (
                        <ProjectRequestForm
                            onSubmit={handleSubmit}
                            initialValues={request}
                        />
                    )
                }
            </div>
        </div>
    </div>;
}