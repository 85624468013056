import { DealerApplicationStatus, dealerApplicationStatusLabels } from "@/common/enums";
import { asOptionalString } from "@/common/utils/zod";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { DealersListFilters } from "../components/dealers-list-filters";
import useSWR from "swr";
import { listDealers } from "../api";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/components/ui/table";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { TablePagination } from "../../common/components/ui/table-pagination";
import { Button, buttonVariants } from "@/common/components/ui/button";
import { EyeIcon } from "lucide-react";
import { DealerApplicationStatusComponent } from "../components/dealer-application-status-component";

export const filtersSchema = z.object({
    applicationStatus: asOptionalString(z.nativeEnum(DealerApplicationStatus)),
    businessName: asOptionalString(z.string()),
    id: asOptionalString(z.coerce.number()),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(10),
});

export type FiltersSchemaType = z.infer<typeof filtersSchema>;

export function DealersListPage() {
    const [searchParams, _] = useSearchParams();

    /**
     * Parse filters.
     * 
     * default return value of `URLSearchParams.get` is `null`
     * but schema expects `undefined` not null.
     * 
     */
    const filters = filtersSchema.parse({
        applicationStatus: searchParams.get('applicationStatus') || undefined,
        businessName: searchParams.get('businessName') || undefined,
        id: searchParams.get('id') || undefined,
        page: searchParams.get('page') || undefined,
        perPage: searchParams.get('perPage') || undefined,
    });

    return <div className="flex flex-col gap-4">
        <h1 className="text-3xl font-bold">Dealers</h1>
        <div className="bg-card p-4 shadow rounded-lg">
            <DealersListFilters filters={filters} />
        </div>
        <div className="bg-card p-4 rounded-md shadow-md">
            <DealersList filters={filters} />
        </div>
    </div>
}

function DealersList({ filters } : { filters: FiltersSchemaType }) {

    const { 
        data, 
        error, 
        isLoading 
    } = useSWR(['/admin/dealers', filters], (key) => {
        let query = key[1];
        return listDealers(query);
    });

    if (isLoading) {
        return <span>Loading...</span>
    }

    if (error) {
        return <AppErrorMessage error={error} />
    }

    return <div className="flex flex-col gap-3">
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>
                        ID
                    </TableHead>
                    <TableHead>
                        Dealer
                    </TableHead>
                    <TableHead>
                        Application Status
                    </TableHead>
                    <TableHead>
                        Representative
                    </TableHead>
                    <TableHead>
                        Mobile
                    </TableHead>
                    <TableHead>
                        Office
                    </TableHead>
                    <TableHead>
                        Actions
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    data?.data.map(dealer => (
                        <TableRow key={dealer.id}>
                            <TableCell>
                                { dealer.id }
                            </TableCell>
                            <TableCell>
                                { dealer.businessName }
                            </TableCell>
                            <TableCell>
                                <DealerApplicationStatusComponent
                                    dealer={dealer}
                                />
                            </TableCell>
                            <TableCell>
                                { dealer.representativeName }
                            </TableCell>
                            <TableCell>
                                {
                                    dealer.mobilePhone && (
                                        <a href={`tel:${dealer.mobilePhone}`}>
                                            { dealer.mobilePhone }
                                        </a>
                                    )
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    dealer.officePhone && (
                                        <a href={`tel:${dealer.officePhone}`}>
                                            { dealer.officePhone }
                                        </a>
                                    )
                                }
                            </TableCell>
                            <TableCell>
                            <div className="flex gap-2 flex-wrap">
                                <Link 
                                    className={buttonVariants({ variant: 'outline' })} 
                                    to={`${dealer.id}`}>
                                    View
                                </Link>
                                <Link 
                                    className={buttonVariants({ variant: 'outline' })}
                                    to={`/app/admin/projects?dealerId=${dealer.id}`}
                                >
                                    Projects
                                </Link>
                                <Link 
                                    className={buttonVariants({ variant: 'outline' })}
                                    to={`/app/admin/project-requests?dealerId=${dealer.id}`}
                                >
                                    Project Requests
                                </Link>
                            </div>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
        <div className="flex justify-end">
            <TablePagination  
                page={filters.page} 
                perPage={filters.perPage} 
                total={data?.total || 0}
            />
        </div>
    </div>
}
