import { useAuthUser } from "@/auth/hooks/use-auth-user"
import { Button } from "@/common/components/ui/button";
import { DealerApplicationStatus, UserRole } from "@/common/enums";
import { DealerBasic } from "@/dealer/entities/dealer";
import { useState } from "react";
import { approveDealer, rejectDealer } from "../api";
import { toast } from "sonner"
import { getErrorMessage } from "@/common/components/ui/app-error-message";

function useApprove(dealerId: number) {

    const [submitting, setSubmitting] = useState(false);

    const [error, setError] = useState<unknown>(null);

    const approve = async () => {
        try {
            setSubmitting(true);
            setError(null);
            await approveDealer(dealerId);
        } catch(e) {
            setError(e);
            throw e;
        } finally {
            setSubmitting(false);
        }
    }

    return {
        error,
        submitting,
        approve,
    }
}

function useReject(dealerId: number) {

    const [submitting, setSubmitting] = useState(false);

    const [error, setError] = useState<unknown>(null);

    const reject = async () => {
        try {
            setSubmitting(true);
            setError(null);
            await rejectDealer(dealerId);
        } catch(e) {
            setError(e);
            throw e;
        } finally {
            setSubmitting(false);
        }
    }

    return {
        error,
        submitting,
        reject,
    }
}

export function DealerActions({ dealer } : { dealer: DealerBasic }) {
    const { initialized, user } = useAuthUser();
    const isAdmin = initialized && user?.role === UserRole.Admin;
    
    const canApproveOrReject = dealer?.applicationStatus === DealerApplicationStatus.Submitted;

    return <div className="flex gap-2">
        {
            canApproveOrReject && <>
                <ApproveButton dealerId={dealer?.id} />
                <RejectButton dealerId={dealer?.id} />
            </>
        }
    </div>
}

function ApproveButton({ dealerId } : { dealerId: number }) {
    const { submitting, approve } = useApprove(dealerId);

    const handleClick : React.MouseEventHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await approve();
            toast.success("Approved")
        } catch(e) {
            toast.error(getErrorMessage(e));
        }
    }

    return <Button 
        variant='success' 
        disabled={submitting}
        onClick={handleClick}
    >
        Approve
    </Button>
}

function RejectButton({ dealerId } : { dealerId: number }) {
    const { submitting, reject } = useReject(dealerId);

    const handleClick : React.MouseEventHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await reject();
            toast.success("Approved")
        } catch(e) {
            toast.error(getErrorMessage(e));
        }
    }

    return <Button 
        variant='outline' 
        disabled={submitting}
        onClick={handleClick}
    >
        Reject
    </Button>
}