import { FormControl } from "@/common/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/common/components/ui/select";
import { US_STATES } from "@/common/data/us-states";
import { ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";

export function StateSelectFormControl<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ field, className }: { field: ControllerRenderProps<TFieldValues, TName>, className?: string }) {
    return <Select
        defaultValue={field.value}
        onValueChange={field.onChange}>
        <FormControl>
            <SelectTrigger className={className}>
                <SelectValue />
            </SelectTrigger>
        </FormControl>
        <SelectContent>
            {US_STATES.map(state => (
                <SelectItem value={state.code} key={state.code}>
                    {state.name}
                </SelectItem>
            ))}
        </SelectContent>
    </Select>;
}
