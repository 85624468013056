import { useAuthUser } from "@/auth/hooks/use-auth-user";
import { useDealer } from "../../hooks/use-dealer";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { DealerApplicationStatus, UserRole } from "@/common/enums";
import { Navigate } from "react-router-dom";

/**
 * 
 * Displays children only after dealer's application is approved.
 * Otherwise redirects to appropriate page.
 * 
 */
export function DealerApplicationRedirector({ children } : { children: React.ReactElement }) {

    const { user, initialized } = useAuthUser();
    const { data: dealer, isLoading: dealerInfoLoading, error } = useDealer();
    const isAdmin = user?.role === UserRole.Admin;

    const isLoading = !initialized || dealerInfoLoading || dealer === undefined;
    const isError = !!error;

    if (isLoading) {
        // console.log('loading');
        return <span>
            Loading...
        </span>;
    }

    if (isError) {
        // console.log('error');
        return <AppErrorMessage error={error} />;
    }

    if (isAdmin || dealer?.applicationStatus === DealerApplicationStatus.Approved) {
        // console.log('isadmin || approved', isAdmin, dealer?.applicationStatus);
        return children;
    }

    // console.log("dealer", dealer);

    return <Navigate to='/dealer-application' />
}