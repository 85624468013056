import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/common/components/ui/dialog";
import { useState } from "react";
import { toast } from "sonner";
import { adminChangeProjectStatus } from "../api";
import { ChangeProjectStatusForm, FormSchemaType } from "./change-project-status-form";

export function ChangeProjectStatusPopup({
    open,
    onOpenChange,
    projectId,
    onSuccess,
}: {
    open: boolean,
    onOpenChange: (open: boolean) => void,
    onSuccess: () => void,
    projectId: number,
}) {

    const [submitting, setSubmitting] = useState(false);

    const onDone = async (value: FormSchemaType) => {
        try {
            setSubmitting(true);
            await adminChangeProjectStatus(projectId, value);
            toast.success("Changed Successfully");
            onSuccess();
        } finally {
            setSubmitting(false);
        }
    }

    return <Dialog
        open={open}
        onOpenChange={(open) => {
            if (submitting) {
                return
            }
            onOpenChange(open);
        }}>
        <DialogContent className="overflow-hidden">
            <DialogHeader>
                <DialogTitle>Change Project Status</DialogTitle>
            </DialogHeader>
            <ChangeProjectStatusForm
                onSubmit={onDone}
            />

        </DialogContent>
    </Dialog>
}