import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { UploadFormControl } from "@/common/components/form-controls/upload-form-control";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Textarea } from "@/common/components/ui/textarea";
import { cn } from "@/common/utils/cn";
import { uploadedAssetSchema } from "@/uploaded-assets/entities/uploaded-asset";
import { zodResolver } from "@hookform/resolvers/zod";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { PlusIcon, TrashIcon } from "lucide-react";

const minProjects = 3;

const pastProjectsFormSchema = z.object({
    pastProjects: z.array(z.object({
        id: z.optional(z.number()),
        description: z.string().min(1, {
            message: 'required',
        }).max(255, {
            message: 'maximum 255 characters'
        }),
        assets: z.array(uploadedAssetSchema).min(3, {
            message: 'Please upload minium 3 images',
        }),
    })).min(minProjects)
})
export type PastProjectsFormSchemaType = z.infer<typeof pastProjectsFormSchema>;

export function DealerPastProjectsForm({
    onSubmit,
    initialValues,
    renderSubmitButton,
}: {
    onSubmit: (data: PastProjectsFormSchemaType) => Promise<void>,
    initialValues?: PastProjectsFormSchemaType,
    renderSubmitButton?: (submitting: boolean) => React.ReactElement
}) {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<PastProjectsFormSchemaType>({
        resolver: zodResolver(pastProjectsFormSchema),
        defaultValues: initialValues || undefined,
    });

    const { fields, append, remove } = useFieldArray({
        name: 'pastProjects',
        control: form.control,
    });

    const handleAddRow: React.MouseEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        append({
            description: '',
            assets: [],
        })
    }

    const handleSubmit = async (
        values: PastProjectsFormSchemaType,
    ): Promise<any> => {

        try {
            setError(null);
            setSubmitting(true);
            await onSubmit(values);
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} className="mb-4" />
            <div className="flex flex-col gap-8">
                {
                    fields.map((field, index) => (
                        <div key={field.id} className="">
                            <div className="flex flex-wrap justify-between">
                                <h3 className="text-lg">Project { index + 1 }</h3>
                                <Button 
                                    disabled={index <= minProjects - 1}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        remove(index);
                                    }}
                                    size='icon' 
                                    variant='outline'>
                                    <TrashIcon size={16} />
                                </Button>
                            </div>
                            <div className="flex flex-col gap-4 mt-3">
                                <FormField
                                    control={form.control}
                                    name={`pastProjects.${index}.description`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>A bit about your project</FormLabel>
                                            <FormControl>
                                                <Textarea {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name={`pastProjects.${index}.assets`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <UploadFormControl
                                                field={field}
                                                dashboardProps={{
                                                    height: '280px',
                                                }}
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="mt-8 text-center">
                <Button
                    variant={'outline'}
                    size='lg'
                    onClick={handleAddRow}
                >
                    <PlusIcon size={16} /> Add Additional Project
                </Button>
            </div>
            <div className={cn('mt-12', { "text-right": !renderSubmitButton })}>
                {
                    !!renderSubmitButton
                        ? renderSubmitButton(submitting)
                        : <Button type="submit" size='lg' disabled={submitting}>
                            Submit
                        </Button>
                }
            </div>
        </form>
    </Form>
}