import {  EmployeeCountValues } from "../api/create-or-update-dealer";
import { Input } from "@/common/components/ui/input";
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
} from '@/common/components/ui/select';
import { SelectValue } from "@radix-ui/react-select";
import { Button } from "@/common/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Textarea } from "@/common/components/ui/textarea";
import { z } from "zod";
import { useState } from "react";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { asOptionalString } from "@/common/utils/zod";
import { cn } from "@/common/utils/cn";

export const dealerFormSchema = z.object({
    businessName: z.string().min(1, {
        message: 'required',
    }),

    employeesCount: z.string().min(1, {
        message: 'required',
    }),

    yearsOfExperience: z.coerce.number()
        .int({ message: 'must be number' })
        .min(1, {
            message: 'minimum 1 year',
        }),

    mobilePhone: z.string().min(10, {
        message: 'mobile number with country code',
    }),

    expertiseSummary: z.string().min(1, {
        message: 'required',
    }),

    taxId: asOptionalString(z.string().min(1)),

    representativeName: z.string().min(1),

    officePhone: asOptionalString(z.string().min(1)),
});

export type DealerFormSchemaType = z.infer<typeof dealerFormSchema>;

// Make every property required because we cannot use undefined as value for controlled inputs
// Removing this would make allow undefined 
// which could lead to uncontrolled input being converted to controlled input.
export type DealerFormInitialValuesType = Required<DealerFormSchemaType>

export function DealerBasicInfoForm({
    onSubmit,
    initialValues,
    renderSubmitButton,
}: {
    onSubmit: (data: DealerFormSchemaType) => Promise<void>,
    initialValues?: DealerFormInitialValuesType,
    renderSubmitButton?: (submitting: boolean) => React.ReactElement
}) {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<DealerFormSchemaType>({
        resolver: zodResolver(dealerFormSchema),
        defaultValues: initialValues || undefined,
    });

    const handleSubmit = async (
        values: DealerFormSchemaType,
    ): Promise<any> => {

        try {
            setError(null);
            setSubmitting(true);
            await onSubmit(values);
        } catch(e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }


    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} className="mb-4" />
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 sm:col-span-1">
                    <FormField
                        control={form.control}
                        name='businessName'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Company Name*</FormLabel>
                                <FormControl>
                                    <Input placeholder="Company Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="col-span-2 sm:col-span-1">
                    <FormField
                        control={form.control}
                        name='representativeName'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Representative Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Representative Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="col-span-2 sm:col-span-1">
                    <FormField
                        control={form.control}
                        name='employeesCount'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Employees*</FormLabel>
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {
                                            EmployeeCountValues.map(v => (
                                                <SelectItem value={v} key={v}>
                                                    {v}
                                                </SelectItem>
                                            ))
                                        }
                                    </SelectContent>
                                </Select>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="col-span-2 sm:col-span-1">
                    <FormField
                        control={form.control}
                        name='yearsOfExperience'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Years of experience*</FormLabel>
                                <FormControl>
                                    <Input type="number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="col-span-1">
                    <FormField
                        control={form.control}
                        name='mobilePhone'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Mobile Phone*</FormLabel>
                                <FormControl>
                                    <Input placeholder="Mobile Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="col-span-1">
                    <FormField
                        control={form.control}
                        name='officePhone'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Office Phone</FormLabel>
                                <FormControl>
                                    <Input placeholder="Office Phone" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="col-span-2">
                    <FormField
                        control={form.control}
                        name='taxId'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Tax ID</FormLabel>
                                <FormControl>
                                    <Input placeholder="Tax ID" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                
                <div className="col-span-2">
                    <FormField
                        control={form.control}
                        name='expertiseSummary'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Your Expertise*</FormLabel>
                                <FormControl>
                                    <Textarea
                                        className="resize-none"
                                        {...field}
                                    />
                                </FormControl>
                                <FormDescription>
                                    Please describe your companies capabilites.
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className={
                    cn(
                        "col-span-2", 
                        { "text-right": !renderSubmitButton}
                    )}
                >
                    {
                        !!renderSubmitButton
                            ? renderSubmitButton(submitting)
                            : <Button type="submit" size='lg' disabled={submitting}>
                                Submit
                            </Button>
                    }
                </div>
            </div>
        </form>
    </Form>
}