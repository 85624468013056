import { DealerApplicationStatus, ProjectRequestStatus, ProjectStatus } from '@/common/enums';
import axios from '../axios/instance';

type CountByStatus<T extends string | number | symbol> = Partial<Record<T, number>>

export type ProjectsCountByStatus = CountByStatus<ProjectStatus>;

export type ProjectRequestsCountByStatus = CountByStatus<ProjectRequestStatus>;

export type DealersCountByStatus = CountByStatus<DealerApplicationStatus>;

export type DealerDashboardResponse = {
    counts: {
        projects: ProjectsCountByStatus,
        projectRequests: ProjectRequestsCountByStatus,
    }
}

export type AdminDashboardResponse = {
    counts: {
        dealers: DealersCountByStatus,
        projects: ProjectsCountByStatus,
        projectRequests: ProjectRequestsCountByStatus,
    }
}

export async function dealerDashboard() {
    const { data } = await axios.get<DealerDashboardResponse>('/dashboard');
    return data;
}


export async function adminDashboard() {
    const { data } = await axios.get<AdminDashboardResponse>('/admin/dashboard');
    return data;
}