import { buttonVariants } from '@/common/components/ui/button';
import { Separator } from '@/common/components/ui/separator';
import { SignupForm } from '../components/signup-form';
import { Link } from 'react-router-dom';

export function SignupPage() {
    return <div className="flex justify-center items-start lg:items-center mt-16">
        <div className="bg-card shadow-lg rounded-lg w-full max-w-lg my-8 px-2">
            <div className="mt-16 mb-14">
                {/* <div className='flex justify-center'>
                    <img className='w-[300px] h-auto' src={LOGO_PATH} />
                </div> */}
                <h1 className="text-3xl text-center font-bold">Signup!</h1>
                <p className="mt-4 text-foreground/50 text-center">Signup for dealer portal.</p>
            </div>
            <div className="p-3 md:px-8">
                <SignupForm />
            </div>
            <div className='flex justify-center'>
                <Separator className='mt-5 max-w-[400px]' />
            </div>
            
            <div className='text-center mt-4 my-8'>
                <p className='text-foreground/50'>
                    Already have an account? 
                    <Link 
                        to="/auth/login" 
                        className={buttonVariants({ variant: 'link', className: 'text-base px-2' })}>
                        Login
                    </Link>
                </p>
            </div>
        </div>
    </div>
}