import { useAuthUser } from "@/auth/hooks/use-auth-user";
import { LOGO_PATH } from "@/brand/constants";
import { UserRole } from "@/common/enums";
import { cn } from "@/common/utils/cn";
import { Link } from "react-router-dom";


export function MainNav({
    className, 
    mobile = false,
    ...props
}: React.HTMLAttributes<HTMLElement> & { mobile?: boolean }) {
    const { initialized, user } = useAuthUser();

    const linkClassName = cn({
        "text-sm font-medium text-muted-foreground transition-colors hover:text-primary": !mobile,
        "text-xl transition-colors hover:text-primary text-foreground": mobile,
    });

    const containerClassName = cn({
        "flex items-center space-x-4 lg:space-x-6": !mobile,
        "flex flex-col gap-3": mobile,
    }, className)

    return (
        <nav
            className={containerClassName}
            {...props}
        >
            {
                mobile && (
                    <div className="self-center mb-5">
                        <img
                            src={LOGO_PATH}
                            className="h-20"
                        />
                    </div>
                )
            }
            <Link
                to="/app"
                className={linkClassName}
            >
                Dashboard
            </Link>
            {initialized && user?.role === UserRole.Dealer && (
                <>
                    <Link
                        to="/app/dealer/projects"
                        className={linkClassName}
                    >
                        Projects
                    </Link>
                    <Link
                        to="/app/dealer/project-requests"
                        className={linkClassName}
                    >
                        Project Requests
                    </Link>
                </>
            )}
            {initialized && user?.role === UserRole.Admin && (
                <>
                    <Link
                        to="/app/admin/projects"
                        className={linkClassName}
                    >
                        Projects
                    </Link>
                    <Link
                        to="/app/admin/project-requests"
                        className={linkClassName}
                    >
                        Project Requests
                    </Link>
                    <Link
                        to="/app/admin/dealers"
                        className={linkClassName}
                    >
                        Dealers
                    </Link>
                </>
            )}
        </nav>
    );
}
