import { Button } from "@/common/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from "@/common/components/ui/dialog";
import { Loader2Icon } from "lucide-react";
import { useState } from "react";
import { dealerRejectProjectRequests } from "../api";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";


export const RejectRequestPopup = ({
    requestId, open, onOpenChange, onDone,
}: {
    requestId: number;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onDone: () => void;
}) => {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const handleSubmit: React.MouseEventHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            setError(null);
            setSubmitting(true);
            await dealerRejectProjectRequests(requestId);
            onDone();
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    };

    return <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Are You Sure?</DialogTitle>
            </DialogHeader>
            {
                !!error && (
                    <div className="my-4">
                        <AppErrorMessage error={error}/>
                    </div>
                )
            }
            <DialogFooter className="sm:justify-start">
                <Button
                    disabled={submitting}
                    variant={'destructive'}
                    onClick={handleSubmit}
                >
                    {submitting && (
                        <Loader2Icon className="w-4 mr-1 animate-spin" />
                    )}
                    Yes
                </Button>
                <Button
                    variant={'secondary'}
                    onClick={() => onOpenChange(false)}
                >
                    No
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>;
};
