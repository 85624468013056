import { useDealer } from "@/dealer/hooks/use-dealer";
import { StepProps } from "../dealer-application-multistep-form";
import { NextPrevButtons } from "../next-prev-bottons";
import { DealerLicensesForm } from "@/dealer/forms/dealer-licenses-form";
import { dealerLicensesFormHandler } from "@/dealer/forms/dealer-licenses-form-action";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { StepFormSkleton, StepSkeleton } from "../step-skleton";

export function LicensesFormStep(props: StepProps) {
    const { data: dealer, error, isLoading } = useDealer();

    if (error || isLoading) {
        return null;
    }

    const threeEmptyLicenses = [
        { stateCode: '', number: '' },
        { stateCode: '', number: '' },
        { stateCode: '', number: '' },
    ]

    if (error) {
        return <AppErrorMessage error={error} />
    }

    if (isLoading) {
        return <StepFormSkleton />
    }

    return <>
        <div className="mt-10">
            <h1 className="text-2xl font-bold">
                Which states you are licensed in?
            </h1>
        </div>
        <div className="mt-8">
            <DealerLicensesForm
                fieldsContainerClassName="max-w-[400px]"
                defaultValues={dealer?.licenses ? {
                    licenses: dealer.licenses.map(license => ({
                        id: license.id,
                        number: license.number,
                        stateCode: license.stateCode,
                    }))
                } : {
                    licenses: threeEmptyLicenses,
                }} 
                onSubmit={async (values) => {
                    await dealerLicensesFormHandler(
                        dealer?.licenses || [], 
                        values
                    );
                    props.goNext();
                }}
                renderSubmitButton={(submitting) => (
                    <NextPrevButtons {...props} submitting={submitting} />
                )}
            />
        </div>
    </>;
}