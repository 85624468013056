import { projectRequestStatusColor, projectRequestStatusLabels } from "@/common/enums"
import { ProjectRequestBasic } from "../entities"

export function ProjectRequestStatusComponent({ 
    projectRequest 
} : {
    projectRequest: ProjectRequestBasic
}) {
    return <div className="items-center flex gap-2">
        <span className="flex-none w-4 h-4 inline-block rounded-full" style={{ backgroundColor: projectRequestStatusColor[projectRequest.status] }}></span>
        {projectRequestStatusLabels[projectRequest.status]}
    </div>
}