import { ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";
import { Dropzone, DropzoneProps } from "../inputs/dropzone";
import { UploadedAssetBasic } from "@/uploaded-assets/entities/uploaded-asset";


export function UploadFormControl<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ 
    field, 
    className, 
    ...props 
} : { 
    field: ControllerRenderProps<TFieldValues, TName>, 
    className?: string 
} & Omit<DropzoneProps, 'defaultValue' | 'onChange'>
) {
    const { onChange, value, name } = field;

    return <Dropzone
        defaultValue={value}
        onChange={onChange}
        {...props}
    />
}
