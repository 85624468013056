import React from "react";
import { ReactComponent as CompletedIcon } from '../../../common/images/illustrations/completed.svg';

export function DealerApplicationInReview() {
    return <div className="w-full p-8 flex justify-center items-center">
        <div className="flex flex-col items-center">
            <CompletedIcon className="w-auto h-[200px]" />
            <h3 className="mt-8 text-green-500 text-3xl text-center">
                Thanks for submitting your application.
            </h3>
            <p className="mt-4 max-w-sm text-center text-lg inline-block">
                Your application is under review. You would be notified when its approved.
            </p>
        </div>
    </div>;
}
