import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/common/components/ui/dialog";
import { useState } from "react";
import { toast } from "sonner";
import { adminAddProjectPayment } from "../api";
import { AddPaymentForm, FormSchemaType } from "./add-payment-form";

export function AddPaymentPopup({
    open,
    onOpenChange,
    projectId,
    onSuccess,
}: {
    open: boolean,
    onOpenChange: (open: boolean) => void,
    onSuccess: () => void,
    projectId: number,
}) {

    const [submitting, setSubmitting] = useState(false);


    const onDone = async (value: FormSchemaType) => {
        try {
            setSubmitting(true);
            await adminAddProjectPayment(projectId, value);
            toast.success("Added Payment");
            onSuccess();
        } finally {
            setSubmitting(false);
        }
    }

    const onCancel = () => {
        onOpenChange(false);
    }

    return <Dialog
        open={open}
        onOpenChange={(open) => {
            if (submitting) {
                return
            }
            onOpenChange(open);
        }}>
        <DialogContent className="overflow-hidden">
            <DialogHeader>
                <DialogTitle>Add Payment</DialogTitle>
            </DialogHeader>

            <AddPaymentForm
                onSubmit={onDone}
            />

        </DialogContent>
    </Dialog>
}