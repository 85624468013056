import axios from '../../axios/instance';

export type LoginRequest = {
    email: string,
    password: string,
}

export type Tokens = {
    accessToken: string
    refreshToken: string
    accessTokenExpiryTs: number
    refreshTokenExpiryTs: number
}

export async function login(request: LoginRequest) {
    const { data } = await axios.post<Tokens>('/auth/login', request, {
        skipAuth: true,
    });
    return data;
}