import { Button } from "@/common/components/ui/button"
import { useState } from "react"
import { RejectRequestPopup } from "./reject-request-popup";

export const RejectRequestButton = ({ 
    requestId,
    onDone,
}: {
    requestId: number,
    onDone: () => any,
}) => {
    const [popupOpen, setPopupOpen] = useState(false);
    return <>
        <RejectRequestPopup
            requestId={requestId}
            open={popupOpen} 
            onOpenChange={setPopupOpen} 
            onDone={onDone}
        />
        <Button 
            variant={'destructive'} 
            onClick={() => setPopupOpen(true)}>
            Reject
        </Button>
    </>
}