import { ProjectRequestStatus, ProjectStatus } from "@/common/enums";
import useSWR from "swr";
import { dealerDashboard } from "../api";
import { StatItem } from "./stat-item";
import { StatsSkleton } from "./stats-skleton";

export function DealerDashboardStats() {
    const {
        isLoading, data,
    } = useSWR('/dashboard', dealerDashboard);

    if (isLoading || data === undefined) {
        return <StatsSkleton />;
    }

    return <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {data?.counts.projectRequests.pending && <div className="col-span-1">
            <StatItem
                title="New Project Requests"
                value={data.counts.projectRequests.pending}
                url={`/app/dealer/project-requests?status=${ProjectRequestStatus.Pending}`} />
        </div>}
        {data?.counts.projects.in_progress && <div className="col-span-1">
            <StatItem
                title="Ongoing Projects"
                value={data.counts.projects.in_progress}
                url={`/app/dealer/projects?status=${ProjectStatus.InProgress}`} />
        </div>}
        {data?.counts.projects.submitted && <div className="col-span-1">
            <StatItem
                title="Waiting for approval"
                value={data.counts.projects.submitted}
                url={`/app/dealer/projects?status=${ProjectStatus.Submitted}`} />
        </div>}
        {data?.counts.projects.requires_resubmission && <div className="col-span-1">
            <StatItem
                title="Resubmission Required"
                value={data.counts.projects.requires_resubmission}
                url={`/app/dealer/projects?status=${ProjectStatus.RequiresResubmission}`} />
        </div>}
        {data?.counts.projects.approved && <div className="col-span-1">
            <StatItem
                title="Approved Projects"
                value={data.counts.projects.approved}
                url={`/app/dealer/projects?status=${ProjectStatus.Approved}`} />
        </div>}
        {data?.counts.projects.rejected && <div className="col-span-1">
            <StatItem
                title="Rejected Projects"
                value={data.counts.projects.rejected}
                url={`/app/dealer/projects?status=${ProjectStatus.Rejected}`} />
        </div>}
    </div>;
}
