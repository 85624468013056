import { Skeleton } from "@/common/components/ui/skeleton";

export function StepSkeleton() {
    return <div className="flex flex-col gap-8">
        <div className="flex gap-4">
            {Array.from({ length: 4 }).map((_, i) => (
                <Skeleton className="w-11 h-2 rounded-lg" key={i} />
            ))}
        </div>
        <StepFormSkleton />
    </div>
}

export function StepFormSkleton() {
    return <div className="w-full">
        <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1">
                <Skeleton className="w-full h-10 rounded" />
            </div>
            <div className="col-span-1">
                <Skeleton className="w-full h-10 rounded" />
            </div>
            <div className="col-span-1">
                <Skeleton className="w-full h-10 rounded" />
            </div>
            <div className="col-span-1">
                <Skeleton className="w-full h-10 rounded" />
            </div>
            <div className="col-span-2">
                <Skeleton className="w-full h-24 rounded" />
            </div>
            <div className="col-span-2">
                <div className="flex justify-between">
                    <Skeleton className="w-[120px] h-10 rounded" />
                    <Skeleton className="w-[120px] h-10 rounded" />
                </div>
                {/* <Skeleton className="w-full h-24 rounded"/> */}
            </div>
        </div>
    </div>
}