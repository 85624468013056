import { GenericIDResponse } from '@/common/types';
import axios from '../../axios/instance';

export const EmployeeCountValues = [
    '1-10',
    '10-50',
    '50+',
] as const;

export type DealerUptateDto = {
    businessName: string

    employeesCount: string

    yearsOfExperience: number

    mobilePhone: string

    expertiseSummary?: string

    taxId?: string

    representativeName?: string
 
    officePhone?: string
}

export async function createOrUpdateDealer(request: DealerUptateDto) {
    const { data } = await axios.put<GenericIDResponse>('/dealer', request);
    return data;
}