import { useDealer } from "@/dealer/hooks/use-dealer";
import { StepProps } from "../dealer-application-multistep-form";
import { DealerPastProjectsForm, PastProjectsFormSchemaType } from "@/dealer/forms/dealer-past-projects-form";
import { NextPrevButtons } from "../next-prev-bottons";
import { useEffect, useMemo, useState } from "react";
import { dealerPastProjectsFormAction } from "@/dealer/forms/dealer-past-projects-form-action";
import { submitApplication } from "@/dealer/api/application";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { StepFormSkleton } from "../step-skleton";

function useSubmitDealerApplication() {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<any>(null);
    const [done, setDone] = useState<any>(false);

    const submit = async () => {
        if (submitting) {
            return;
        }
        try {
            setError(null);
            setSubmitting(true);
            await submitApplication();
            setDone(true);
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return {
        done,
        error,
        submit,
        submitting,
    }
}

export function PastProjectsFormStep (props: StepProps) {

    const { data: dealer, error, isLoading } = useDealer();


    const { 
        error: submitError,
        done, 
        submitting, 
        submit, 
    } = useSubmitDealerApplication();



    const initialValues = useMemo(() => {
        const hasProjects = dealer?.pastProjects && dealer.pastProjects.length > 0;
        
        if (!hasProjects) {
            const threeEmptyProjects : PastProjectsFormSchemaType = {
                pastProjects: [
                    { assets: [], description: '' },
                    { assets: [], description: '' },
                    { assets: [], description: '' },
                ]
            };
            return threeEmptyProjects;
        }

        return {
            pastProjects: dealer.pastProjects.map(p => ({
                id: p.id,
                description: p.description,
                assets: p.assetsJoin.map(a => a.asset)
            }))
        }
    }, [ dealer?.pastProjects ]);

    useEffect(() => {
        if (done) {
            props.markSubmitted();
        }
    }, [props, done]);

    if (error) {
        return <AppErrorMessage error={error} />
    }

    if (isLoading) {
        return <StepFormSkleton />
    }

    return <>
        <div className="mt-10">
            <h1 className="text-2xl font-bold">
                Share some of your past projects?
            </h1>
            <p className="text-card-foreground/50 mt-2">
                Share atleast 3 projects, with 3 images each.
            </p>
        </div>
        <div className="mt-8">
            <AppErrorMessage error={submitError} className="my-4" />
            <DealerPastProjectsForm 
                onSubmit={async (data) => {
                    await dealerPastProjectsFormAction(dealer?.pastProjects || [], data);
                    await submit();
                }}
                initialValues={initialValues}
                renderSubmitButton={(submittingForm) => (
                    <div className="flex flex-col gap-4">
                        <AppErrorMessage error={submitError} />
                        <NextPrevButtons {...props} submitting={submittingForm || submitting} />
                    </div>
                )}
            />            
        </div>
    </>
}