import { dealerApplicationStatusColors, dealerApplicationStatusLabels } from "@/common/enums"
import { DealerBasic } from "@/dealer/entities/dealer"

export function DealerApplicationStatusComponent({
    dealer
}: {
    dealer: DealerBasic,
}) {
    return <div className="items-center flex gap-2">
        <span className="flex-none w-4 h-4 inline-block rounded-full" style={{ backgroundColor: dealerApplicationStatusColors[dealer.applicationStatus] }}></span>
        {dealerApplicationStatusLabels[dealer.applicationStatus]}
    </div>
}