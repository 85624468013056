import { AddressBasic } from "../entity";

export function Address({ address } : { address: AddressBasic }) {
    return <div>
        {address?.addressLine1 ? <div>{address.addressLine1}</div> : null}
        {address?.addressLine2 ? <div>{address.addressLine2}</div> : null}
        {(address?.city || address?.state || address?.zipCode) ? (
            <div>
                {[address?.city, address?.state, address?.zipCode]
                    .filter(p => !!p)
                    .join(' ')}
            </div>
        ) : null}
    </div>
}