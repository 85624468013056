import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/components/ui/table";
import { formatMoneyStr } from "@/common/utils/money";
import { ProjectFull } from "../api";
import { formatDate } from "@/common/utils/date";

export function ProjectPaymentsTable({ project }: { project: ProjectFull; }) {
    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead>Amount</TableHead>
                <TableHead>Notes</TableHead>
                <TableHead>Date</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {project.payments.map(payment => (
                <TableRow key={payment.id}>
                    <TableCell>
                        { formatMoneyStr(payment.amount) }
                    </TableCell>
                    <TableCell>
                        {payment.notes}
                    </TableCell>
                    <TableCell>
                        { formatDate(payment.createdAt) }
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>;
}
