import React, { useState } from "react"
import { useDealer } from "../../hooks/use-dealer";
import { cn } from "@/common/utils/cn";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { BasicInfoFormStep } from "./steps/basic-info-form-step";
import { AddressFormStep } from "./steps/address-form-step";
import { LicensesFormStep } from "./steps/licenses-form-step";
import { PastProjectsFormStep } from "./steps/past-projects-form-step";
import { StepContainer } from "./step-container";
import { DealerApplicationInReview } from "./dealer-application-in-review";

export enum DealerApplicationStep {
    BasicInfo,
    Address,
    Licenses,
    Projects,
}

export type StepProps = {
    currentStepIndex: number;
    currentStep: DealerApplicationStep;
    hasNext: boolean;
    hasPrev: boolean;
    submitted: boolean;
    markSubmitted: () => void;
    goNext: () => void;
    goPrev: () => void;
    totalSteps: number;
}

export function DealerApplicationMultistepForm() {
    const { data: dealer, error, isLoading, mutate } = useDealer();

    const steps = [
        DealerApplicationStep.BasicInfo,
        DealerApplicationStep.Address,
        DealerApplicationStep.Licenses,
        DealerApplicationStep.Projects
    ];

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const currentStep = steps[currentStepIndex];
    const [submitted, setSubmitted] = useState(false);

    const hasNext = currentStepIndex < (steps.length - 1);
    const hasPrev = currentStepIndex > 0;
    
    const totalSteps = steps.length;

    const goNext = () => {
        if (hasNext) {
            mutate(undefined, {
                rollbackOnError: false,
            })
            setCurrentStepIndex(i => i +1);
        }
    }

    const goPrev = () => {
        if (hasPrev) {
            mutate(undefined, {
                rollbackOnError: false,
            })
            setCurrentStepIndex(i => i - 1);
        }
    }

    const markSubmitted = () => setSubmitted(true);

    const stepProps : StepProps = {
        submitted,
        markSubmitted,
        currentStep,
        currentStepIndex,
        goNext,
        goPrev,
        hasNext,
        hasPrev,
        totalSteps,
    }

    const stepElements = {
        [DealerApplicationStep.Address]: <AddressFormStep {...stepProps} />,
        [DealerApplicationStep.BasicInfo]: <BasicInfoFormStep {...stepProps} />,
        [DealerApplicationStep.Licenses]: <LicensesFormStep {...stepProps} />,
        [DealerApplicationStep.Projects]: <PastProjectsFormStep {...stepProps}/>,
    }

    if (submitted) {
        return <StepContainer>
            <DealerApplicationInReview />
        </StepContainer>
    }

    return <StepContainer>
        <StepProgress {...stepProps} />
        {/* 
        TODO:
        Proper error / loading ui.
        Projects page / form.
        */}
        {
            isLoading && <span>
                Loading...
            </span>
        }
        {
            error && <AppErrorMessage error={error} />
        }
        {
            !submitted && !isLoading && !error && stepElements[currentStep]
        }
    </StepContainer>
}

function StepProgress( { currentStepIndex, totalSteps  } : StepProps) {
    return <>
        <div className="flex flex-row justify-between items-center flex-wrap gap-4">
            <div className="flex gap-4">
                {
                    Array.from({ length: totalSteps }).map((_, i) => (
                        <div key={i} className={cn(
                            "w-11 h-2 rounded-lg",
                            i <= currentStepIndex ? "bg-primary" : "bg-slate-300"
                        )}></div>
                    ))
                }
            </div>
            <span className="text-xs text-card-foreground/50">
                { currentStepIndex + 1 } / { totalSteps }
            </span>
        </div>
        <div className="mt-4 text-sm text-card-foreground/50">
            Apply to be part of our dealer network.
        </div>
    </>
}