import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Loader2Icon } from "lucide-react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { dealerFindProjectById } from "../api";
import { ProjectInfo } from "../components/project-info";
import { SubmitForApprovalButton } from "../components/submit-for-approval-button";
import { canSubmitProject } from "../utils";

export function DealerViewProjectPage() {
    const { id } = useParams();
    const {
        data: project,
        isLoading,
        error,
        mutate,
    } = useSWR(['/dealer/projects/', id], (key) => {
        const [_, id] = key;
        return dealerFindProjectById(id);
    });

    if (isLoading) {
        return <div>
            <div className="flex justify-center py-4">
                <Loader2Icon className="text-primary w-12 h-12 animate-spin" />
            </div>
        </div>
    }

    if (error) {
        return <div>
            <AppErrorMessage error={error} />
        </div>
    }

    const canSubmit = project
        ? canSubmitProject(project)
        : false;

    return <div className="bg-card rounded p-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <h1 className="text-xl">
                {project?.title}
            </h1>
            <div>
                {
                    canSubmit && (
                        <SubmitForApprovalButton
                            projectId={project!.id}
                            onDone={() => {
                                mutate();
                            }}
                        />
                    )
                }
            </div>
        </div>
        <div className="mt-5">
            {
                project && (
                    <ProjectInfo project={project} />
                )
            }
        </div>
    </div>
}
