import { ReactComponent as CompletedIcon } from '../../../common/images/illustrations/completed.svg';
import { buttonVariants } from "@/common/components/ui/button";
import { Link } from "react-router-dom";
import { cn } from "@/common/utils/cn";

export function DealerApplicationApproved() {
    return <div className="w-full p-8 flex justify-center items-center">
        <div className="flex flex-col items-center">
            <CompletedIcon className="w-auto h-[200px]" />
            <h3 className="mt-8 text-green-500 text-3xl text-center">
                Your Application is approved.
            </h3>
            <Link to='/app' className={cn(buttonVariants({ variant: 'default', size: 'lg' }), 'mt-8')}>
                Dashboard
            </Link>
        </div>
    </div>
}
