import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/common/components/ui/dialog";
import { getSubmission, submitForApproval } from "../api";
import { FormSchemaType, SubmitForApprovalForm } from "./submit-for-approval-form";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { Loader2Icon } from "lucide-react";
import { toast } from "sonner";

export function SubmitForApprovalPopup({
    open,
    onOpenChange,
    projectId,
    onSuccess,
} : {
    open: boolean,
    onOpenChange: (open : boolean) => void,
    onSuccess: () => void,
    projectId: number,
}) {

    const {
        data: submission,
        isLoading,
    } = useSWR(['projects', projectId, 'submission'], async (key) => {
        const projectId = key[1];
        return getSubmission(projectId);
    }, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    const [submitting, setSubmitting] = useState(false);

    const files = useMemo(() => {
        return (submission?.files || [])
            .map(f => f.asset)
    }, [submission]);

    const onDone = async (value: FormSchemaType) => {
        try {
            setSubmitting(true);
            await submitForApproval(projectId, {
                files: value.files.map(f => f.id),
            });
            toast.success("Submitted project");
            onSuccess();
        } finally {
            setSubmitting(false);
        }
    }

    const onCancel = () => {
        onOpenChange(false);
    }

    return <Dialog
        open={open} 
        onOpenChange={(open) => {
            if (submitting) {
                return
            }
            onOpenChange(open);
        }}>
        <DialogContent className="overflow-hidden">
            <DialogHeader>
                <DialogTitle>Submit Your Project</DialogTitle>
            </DialogHeader>
            {
                isLoading && (
                    <div className="flex justify-center p-8">
                        <Loader2Icon className="animate-spin w-10 h-10 text-primary" />
                    </div>
                )
            }
            {
                !isLoading && (
                    <SubmitForApprovalForm
                        initialValues={{ files }}
                        onDone={onDone}
                        onCancel={onCancel}
                    />
                )
            }
        </DialogContent>
    </Dialog>
}