import { cn } from "@/common/utils/cn";

export function InfoTile({
    children,
    className,
    ...props
} : React.HtmlHTMLAttributes<HTMLElement>) {
    return <div className="flex flex-col gap-2">
        { children }        
    </div>
}

export function InfoTitle({
    children,
    className,
    ...props
} : React.HtmlHTMLAttributes<HTMLElement>) {
    return <div className={cn("text-sm text-foreground/50", className)}>
        { children }
    </div>
}

export function InfoContent({
    children,
    className,
    ...props
} : React.HtmlHTMLAttributes<HTMLElement>) {
    return <div className={cn("", className)}>
        { children }
    </div>
}