export class NetworkError extends Error {
    constructor() {
      super('Could not connect to server');
    }
}

export class ApiError extends Error {
    code?: String;
    httpStatusCode?: number;

    constructor(message: string, code?: string, httpStatusCode?: number) {
        super(message);
        this.code = code;
        this.httpStatusCode = httpStatusCode;
    }
}

export class ApiValidationError extends ApiError {
    messages?: string[]

    constructor(messages?: string[], code?: string, httpStatusCode?: number) {
        super('Validation Error', code, httpStatusCode);
        this.messages = messages;
    }
}

export function transformError(error: any) {
    if (error.response) {
        let status = error.response.status as number;
        let data = error.response.data as any;
    
        const code = (data || {})?.errorCode || undefined;

        // api error
        if (data.message && typeof(data.message) === 'string') {
            throw new ApiError(data.message, code, status);
        }

        // validation error
        if (data.message && Array.isArray(data.message)) {
            throw new ApiValidationError(data.message, code, status);
        }

        // unknown api error
        throw new ApiError('Something went wrong', code, status);
    }
    throw new NetworkError();
}