import { Card, CardContent, CardHeader, CardTitle } from "@/common/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/components/ui/table";
import { DealerConfidentialWithRelated } from "@/dealer/entities/dealer";

export function LicensesCard({ dealer, className }: { dealer: DealerConfidentialWithRelated; className?: string; }) {
    return <Card className={className}>
        <CardHeader>
            <CardTitle>Licenses</CardTitle>
        </CardHeader>
        <CardContent>
            {(dealer?.licenses && dealer?.licenses.length > 0) && (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                State
                            </TableHead>
                            <TableHead>
                                License Number
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {dealer.licenses.map(l => (
                            <TableRow key={l.id}>
                                <TableCell>
                                    {l.state}
                                </TableCell>
                                <TableCell>
                                    {l.number}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </CardContent>
    </Card>;
}
