import { createOrUpdateAddress } from "../api/create-or-update-address";
import { AddressFormSchemaType } from "./dealer-address-form";

/**
 * 
 * Separate handler decouples form from it's submission logic.
 * 
 */
export async function dealerAddressFormAction(data: AddressFormSchemaType) {
    await createOrUpdateAddress({
        ...data,
        countryCode: 'US',
    });
}