import { Button } from "@/common/components/ui/button";
import { Command, CommandInput, CommandList, CommandGroup, CommandItem } from "@/common/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/components/ui/popover";
import { DealerApplicationStatus } from "@/common/enums";
import { cn } from "@/common/utils/cn";
import { findById, listDealers } from "@/dealers/api";
import { CheckIcon, XIcon } from "lucide-react";
import { useState } from "react";
import useSWR from "swr";

export function DealerSelectInput({
    value,
    onChange,
    className,
} : {
    value?: number,
    onChange: (value?: number) => void,
    className?: string,
}) {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const { data: dealers } = useSWR(['/admin/search-dealers', searchTerm], async (key) => {
        const searchTerm = key[1];
        if (!searchTerm) {
            return [];
        }
        const { data } = await listDealers({
            page: 1,
            perPage: 20,
            businessName: key[1],
            applicationStatus: DealerApplicationStatus.Approved,
        });
        return data;
    });

    const { data: dealer } = useSWR(['/admin/dealers', value], async (key) => {
        const id = key[1];
        if (!id) {
            return null;
        }
        const data = await findById(id);
        return data;
    })


    return <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
            <Button
                variant='outline'
                role="combobox"
                aria-expanded={open}
                className={cn("flex w-full justify-between", className)}
            >
                { dealer ? dealer.businessName : 'Select Dealer...' }
                <XIcon 
                    className="ml-2 h-4 w-4 shrink-0 opacity-50" 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(false);
                        onChange(undefined);
                    }}
                />
            </Button>
        </PopoverTrigger>
        <PopoverContent className="overflow-y-auto max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)]">
            <Command shouldFilter={false}>
                <CommandInput
                    value={searchTerm}
                    onValueChange={setSearchTerm}
                    placeholder="Search Dealer..."
                />
                <CommandList>
                    <CommandGroup>
                        {
                            (dealers || []).map(dealer => (
                                <CommandItem
                                    disabled={false}
                                    key={dealer.id}
                                    value={dealer.id.toString()}
                                    onSelect={() => {
                                        if (value === dealer.id) {
                                            onChange(undefined);
                                        } else {
                                            onChange(dealer.id);
                                        }
                                        setOpen(false);
                                    }}
                                >
                                    <CheckIcon
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            value && value === dealer.id  
                                                ? "opacity-100" 
                                                : "opacity-0"
                                        )}
                                    />
                                    { dealer.businessName }
                                </CommandItem>
                            ))
                        }
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
}