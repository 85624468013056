import { Address } from "@/address/components/address";
import { Table, TableBody, TableCell, TableRow } from "@/common/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/common/components/ui/tabs";
import { formatDate } from "@/common/utils/date";
import { formatMoneyStr } from "@/common/utils/money";
import { UploadedAssetsGrid } from "../../uploaded-assets/components/uploaded-assets-grid";
import { ProjectFull } from "../api";
import { ProjectStatusWithReason } from "./project-status-with-reason";
import { ProjectPaymentsTable } from "./project-payments-table";

export function ProjectInfo({ project }: { project: ProjectFull; }) {
    return <Tabs defaultValue="info" className="w-full">
        <TabsList className="mb-5 w-full">
            <TabsTrigger value="info" className="flex-1">
                Info
            </TabsTrigger>
            <TabsTrigger value="files" className="flex-1">
                Files
            </TabsTrigger>
            <TabsTrigger value="submission" className="flex-1">
                Submission
            </TabsTrigger>
            <TabsTrigger value="payments" className="flex-1">
                Payments
            </TabsTrigger>
        </TabsList>

        <TabsContent value="info">
            <ProjectBasicInfo project={project} />
        </TabsContent>
        <TabsContent value="files">
            <ProjectFiles project={project} />
        </TabsContent>
        <TabsContent value="submission">
            <ProjectSubmissionInfo project={project} />
        </TabsContent>
        <TabsContent value="payments">
            <ProjectPaymentsInfo project={project}/>
        </TabsContent>
    </Tabs>;
}

function ProjectPaymentsInfo({ project }: { project: ProjectFull }) {
    return <div>
        {/* Summary */}
        <div className="grid grid-cols-3 gap-x-4 gap-y-8">
            <div className="flex flex-col col-span-1 gap-2">
                <span className="font-bold">Total Amount</span>
                <span>
                    { formatMoneyStr(project.price) }
                </span>
            </div>
            <div className="flex flex-col col-span-1 gap-2">
                <span className="font-bold">Paid Amount</span>
                <span>
                    { formatMoneyStr(project.amountPaid) }
                </span>
            </div>
            <div className="flex flex-col col-span-1 gap-2">
                <span className="font-bold">Remaining Amount</span>
                <span>
                    { formatMoneyStr(project.remainingAmount) }
                </span>
            </div>
        </div>
        <hr className="my-5" />
        {/* Table */}
        {(project.payments.length > 0) && (
            <div>
                <ProjectPaymentsTable project={project} />
            </div>
        )}
        {/* No Data */}
        {(project.payments.length <= 0) && (
            <div className="text-center">
                No Data
            </div>
        )}
    </div>
}

function ProjectBasicInfo({ project }: { project: ProjectFull }) {
    return <div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-8">
            <div className="flex flex-col col-span-1 gap-2">
                <span className="font-bold">Status</span>
                <ProjectStatusWithReason project={project} />
            </div>
            <div className="flex flex-col col-span-1 gap-2">
                <span className="font-bold">Price</span>
                <span>{formatMoneyStr(project.price)}</span>
            </div>

            {project.companyName && (
                <div className="flex flex-col col-span-1 gap-2">
                    <span className="font-bold">Company</span>
                    <span>{project.companyName}</span>
                </div>
            )}

            {project.address && (
                <div className="flex flex-col col-span-1 gap-2">
                    <span className="font-bold">Address</span>
                    <span>
                        <Address address={project.address} />
                    </span>
                </div>
            )}

            {(project.specs && project.specs.length > 0) && (
                <div className="flex flex-col col-span-2">
                    <span className="font-bold">Specs</span>
                    <div>
                        <Table>
                            <TableBody>
                                {project?.specs.map((spec, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{spec.key}</TableCell>
                                        <TableCell>{spec.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )}

            {project.details && (
                <div className="flex flex-col col-span-2 gap-2">
                    <span className="font-bold">Details</span>
                    <div>
                        {project.details}
                    </div>
                </div>
            )}

            {project.contract && (
                <div className="flex flex-col col-span-2 gap-2">
                    <span className="font-bold">Contract</span>
                    <div>
                        {project.contract}
                    </div>
                </div>
            )}
        </div>
    </div>;
}

function ProjectFiles({ project }: { project: ProjectFull }) {
    if (project.files.length <= 0) {
        return null;
    }
    return <UploadedAssetsGrid assets={project.files.map(f => f.asset)} />
}

function ProjectSubmissionInfo({ project }: { project: ProjectFull }) {
    if (!project.submission) {
        return null;
    }
    return <div className="flex flex-col col-span-2 gap-4">
        <span className="flex justify-between mb-5">
            <span className="text-muted-foreground">Submission Date</span>
            <span>
                {formatDate(project.submission.updatedAt)}
            </span>
        </span>
        <UploadedAssetsGrid assets={project.submission.files.map(f => f.asset)} />
    </div>
} 