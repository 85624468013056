import { BASE_URL } from "@/axios/constants";
import { UploadedAssetBasic } from "@/uploaded-assets/entities/uploaded-asset";
import Uppy, { FileRemovedCallback, UploadCompleteCallback, UploadedUppyFile, UppyOptions } from "@uppy/core";
import { useEffect, useId, useMemo, useState } from "react";
import XHR, { XHRUploadOptions } from '@uppy/xhr-upload';
import getStore from "@/auth/store";
import { AssetType } from "@/common/enums";
import { Dashboard } from "@uppy/react";
import { DashboardProps } from "@uppy/react/src/Dashboard";

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

export type DropzoneProps = {
    defaultValue?: UploadedAssetBasic[];
    onChange: (files: UploadedAssetBasic[]) => any;
    uppyOptions?: Partial<UppyOptions>;
    xhrOptions?: Partial<XHRUploadOptions>;
    dashboardProps?: Partial<DashboardProps>;
    allowAssets?: boolean
};

export function Dropzone({
    defaultValue,
    onChange,
    uppyOptions,
    xhrOptions,
    dashboardProps,
    allowAssets = false,
} : DropzoneProps) {

    const [values, setValues] = useState<UploadedAssetBasic[]>(defaultValue || []);

    const id = useId();

    useEffect(() => {
        onChange(values);
    }, [values]);

    // setup uppy
	const uppy = useMemo<Uppy<any, UploadedAssetBasic>>(() => {

        const endpoint = allowAssets
                ? BASE_URL + 'upload/assets'
                : BASE_URL + 'upload/images';

        const uppy = new Uppy<any, UploadedAssetBasic>({
            id: id,
            autoProceed: false,
            ...(uppyOptions || {}),
        })
        .use(XHR, {
            endpoint,
            fieldName: 'file',
            responseUrlFieldName: 'url',
            headers: (file) => {
                const accessToken = getStore().getTokens()?.accessToken || '';
                return {
                    'Authorization': `Bearer ${accessToken}`
                };
            },
            bundle: false,
            withCredentials: true,
            ...(xhrOptions || {}),
        });

        (defaultValue || []).forEach(file => {
            // debugger
            const fileId = uppy.addFile({
                data: new Blob(),
                name: file.filename,
                id: file.id,
                size: file.size,
            });

            // these fields are not updated when we do addFile
            uppy.setFileState(fileId, {
                // dont add data or id properties here
                isRemote: true,
                progress: {
                    // uppy will reupload of this date is null
                    uploadStarted: (new Date()).getTime(),
                    uploadComplete: true,
                    bytesTotal: file.size,
                    bytesUploaded: file.size,
                    percentage: 100,
                },
                name: file.filename,
                size: file.size,
                response: {
                    status: 201,
                    body: file,
                    uploadURL: file.type === AssetType.Image ? file.url : undefined,
                },
                preview: file.url,
            });
        });

        uppy.setOptions({
            autoProceed: true,
        });

        if ((defaultValue || []).length > 0) {
            uppy.setState({ totalProgress: 100 });
        }

        return uppy;
    }, []);

    // setup event listenrs
    useEffect(() => {

        const onComplete : UploadCompleteCallback<any> = (result) => {
            const addedFiles = ((result.successful || []) as UploadedUppyFile<any, UploadedAssetBasic>[])
                .filter(r => !!(r.response?.body))
                .map(r => r.response!.body);
            setValues(v => [ ...v, ...addedFiles ]);
        }

        const onFileRemoved : FileRemovedCallback<{}> = (file, reason) => {
            const serverResponse = file.response?.body as (UploadedAssetBasic | undefined);
            if (!serverResponse) {
                return
            }
            setValues(v => v.filter(value => value.id != serverResponse.id));
        } 

        uppy.on('complete', onComplete);
        uppy.on('file-removed', onFileRemoved);

        return () => {
            uppy.off('complete', onComplete);
            uppy.off('file-removed', onFileRemoved);
        }

    }, [uppy, setValues]);

    return <Dashboard
        uppy={uppy}
        plugins={[ 'XHR' ]}
        proudlyDisplayPoweredByUppy={false}
        showRemoveButtonAfterComplete={true}
        // @ts-ignore
        doneButtonHandler={null}
        // @ts-ignore
        showRemoveButtonAfterComplete={true}
        {...dashboardProps}
    />
}