import { Navigate } from "react-router-dom";
import { useAuthUser } from "../hooks/use-auth-user";
import { UserRole } from "@/common/enums";

export function Protected({ children, roles }: { children: React.ReactElement, roles?: UserRole[] }) {
    const { user, initialized } = useAuthUser();

    if (!initialized) {
        return <span>...</span>
    }

    if (!user) {
        return <Navigate to='/auth/login' replace />
    }

    if (Array.isArray(roles) && !roles.includes(user.role)) {
        return <Navigate to='/app' replace />
    }

    return children;
}