import { cn } from "@/common/utils/cn";

import { ApiError, ApiValidationError } from "../../../axios/errors";

export function AppErrorMessage ({ error, className } : { error: unknown, className?: string }) {
    const isValidationError = error instanceof ApiValidationError;

    // noerror
    if (!error) {
        return null;
    }

    // list of error messages
    if (isValidationError) {
        return <div className={cn("rounded-sm bg-destructive text-destructive-foreground p-2", className)}>
            <ul>
                {
                    (error.messages || []).map((m,i) => <li key={m}>
                        { m }
                    </li>)
                }
            </ul>
        </div>
    }

    // error message
    return <div className={cn("rounded-sm bg-destructive text-destructive-foreground p-2", className)}>
        { getErrorMessage(error) }
    </div>
}

export function getErrorMessage(error: unknown) {
    if (error === null) {
        return null;
    }
    if (error instanceof ApiValidationError) {
        return (error.messages || [])?.join('');
    }
    if (error instanceof ApiError) {
        return error.message;
    }
    if (error instanceof Error) {
        return (error?.message) || 'Unknown error';
    }
    return 'Unknown error';
}
