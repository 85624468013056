import { z } from "zod";
import { Tokens } from "./api/login";
import { me } from "./api/me";
import getStore from "./store";

export async function loginWithTokens(tokens: Tokens) {
    const store = getStore();
    await store.setTokens(tokens);
    const user = await me();
    await store.setUser(user);
}

export async function logout() {
    const store = getStore();
    await Promise.all([
        store.setUser(null),
        store.setTokens(null),
    ]);
}

export const passwordSchema =  z.string()
    .min(8, 'minimum 8 characters')
    .regex(/[a-z]/, 'atleast 1 lowercase letter')
    .regex(/[A-Z]/, 'atleast 1 uppercase letter')
    .regex(/[0-9]/, 'atleast 1 number')
    .regex(/[^a-zA-Z0-9]/, 'atleast 1 special chracter');