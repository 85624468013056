import { addPastProject, editPastProject, removePastProject } from "../api/past-projects";
import { PastProjectWithAssets } from "../entities/past-project";
import { PastProjectsFormSchemaType } from "./dealer-past-projects-form";


function diffPastProjects(
    oldProjects: PastProjectWithAssets[],
    newProjects: PastProjectsFormSchemaType['pastProjects'],
) {

    const newProjectsIds = newProjects.map(p => p.id);

    const addedProjects = newProjects.filter(l => l.id === undefined);

    const removedProjects = oldProjects.filter(p => !newProjectsIds.includes(p.id));

    const editedProjects = newProjects.filter(p => p.id !== undefined);

    return { addedProjects, removedProjects, editedProjects };
}

export async function dealerPastProjectsFormAction (
    oldProjects: PastProjectWithAssets[],
    data: PastProjectsFormSchemaType,
) {
    const newProjects = data.pastProjects;

    const { addedProjects, removedProjects, editedProjects } = diffPastProjects(oldProjects, newProjects);
    
    const promises = [
        ...addedProjects.map(p => addPastProject({
            description: p.description,
            images: p.assets.map(a => a.id),
        })),
        ...removedProjects.map(p => removePastProject(p.id)),
        ...editedProjects.map(p => editPastProject(p.id!, { 
            description: p.description,
            images: p.assets.map(a => a.id),
        })),
    ];

    await Promise.all(promises);
}