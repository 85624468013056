import { AddressBasic } from "@/address/entity";
import { ProjectPaymentStatus, ProjectStatus } from "@/common/enums";
import { DealerBasic } from "@/dealer/entities/dealer";
import { ProductSpecItem } from "@/project-requests/entities";
import { UploadedAssetsJoinBasic } from "@/uploaded-assets/entities/uploaded-asset";
import axios from '../axios/instance';

export type AdminProjectSearchRequest = {
    id?: number;
    status?: ProjectStatus
    dealerId?: number;
    title?: string;
    paymentStatus?: ProjectPaymentStatus
    page: number;
    perPage: number;
}


export type DealerProjectSearchRequest = {
    id?: number;
    status?: ProjectStatus
    title?: string;
    paymentStatus?: ProjectPaymentStatus
    page: number;
    perPage: number;
}

export type ProjectPaymentBasic = {
    id: number

    amount: string

    notes: string | null

    createdAt: string

    updatedAt: string
}


export type ProjectBasic = {
    id: number

    dealerId: number | null

    status: ProjectStatus

    title: string

    companyName: string | null

    addressId: number | null

    price: string

    specs: ProductSpecItem[]

    resubmissionReason: string | null

    rejectReason: string | null

    amountPaid: string

    remainingAmount: string

    paymentStatus: ProjectPaymentStatus

    createdAt: string

    updatedAt: string
}

export type ProjectListItem = ProjectBasic & {
    dealer: DealerBasic | null;
}

export type ProjectFull = ProjectBasic & {

    dealer: DealerBasic | null;

    address: AddressBasic | null;

    files: UploadedAssetsJoinBasic[];

    contract: string | null

    details: string | null

    submission: SubmissionWithFiles | null

    payments: ProjectPaymentBasic[]
}

export type SubmissionBasic = {
    id: number

    projectId: number;

    createdAt: string

    updatedAt: string
}

export type SubmissionWithFiles = SubmissionBasic & {
    files: UploadedAssetsJoinBasic[];
}

export type ProjectListResponse = {
    data: ProjectListItem[]

    total: number;
}

export type SubmitProjectDto = {
    files: string[]
}

export type ChangeProjectStatusDTO = {
    status: ProjectStatus;

    resubmissionReason?: string

    rejectReason?: string
}

export type AddPaymentRequestDTO = {
    amount: string
    notes?: string;
}

export async function adminListProjects(request: AdminProjectSearchRequest) {
    const { data } = await axios.get<ProjectListResponse>('/admin/projects', {
        params: request,
    });
    return data;
}

export async function adminFindProjectById(id: unknown) {
    const { data } = await axios.get<ProjectFull>(`/admin/projects/${id}`);
    return data;
}

export async function dealerListProjects(request: DealerProjectSearchRequest) {
    const { data } = await axios.get<ProjectListResponse>('/projects', {
        params: request,
    });
    return data;
}

export async function dealerFindProjectById(id: unknown) {
    const { data } = await axios.get<ProjectFull>(`/projects/${id}`);
    return data;
}

export async function submitForApproval(projectId: number, request: SubmitProjectDto) {
    const { data } = await axios.patch<void>(`/projects/${projectId}/submission`, request);
    return data;
}

export async function getSubmission(projectId: number) {
    const { data } = await axios.get<SubmissionWithFiles | null>(`/projects/${projectId}/submission`);
    return data;
}

export async function adminChangeProjectStatus(projectId: number, request: ChangeProjectStatusDTO) {
    const { data } = await axios.patch<void>(`/admin/projects/${projectId}/status`, request);
    return data;
}

export async function adminAddProjectPayment(projectId: number, request: AddPaymentRequestDTO) {
    const { data } = await axios.post<void>(`/admin/projects/${projectId}/payments`, request);
    return data;
}