import { cn } from "@/common/utils/cn";
import { MainNav } from "./main-nav";
import { UserNav } from "./user-nav";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "@/common/components/ui/sheet";
import { MenuIcon } from "lucide-react";
import { LOGO_PATH } from "@/brand/constants";

export function AppNav({
    className, ...props
}: React.HtmlHTMLAttributes<HTMLElement>) {

    return <div
        className={cn("bg-card", className)}
        {...props}
    >
        <div className="container">
            <div className="flex h-24 items-center px-4">
                <div>
                    <img 
                        src={LOGO_PATH}
                        className="h-12 lg:h-20"
                    />
                </div>
                <div className="hidden md:flex ml-auto gap-1">
                    <MainNav className="mx-8" />
                    <UserNav className="" />
                </div>
                <div className="flex flex-row items-center md:hidden ml-auto gap-2">
                    <UserNav />
                    <Sheet>
                        <SheetTrigger>
                            <MenuIcon />
                        </SheetTrigger>
                        <SheetContent>
                            <SheetHeader>
                            <SheetDescription>
                                <MainNav mobile />
                            </SheetDescription>
                            </SheetHeader>
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </div>
    </div>
}
