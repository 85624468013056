import { z } from "zod";

// https://github.com/shadcn-ui/ui/issues/690

const emptyStringToUndefined = z.literal('').transform(() => undefined);

/**
 * Usage min(1) is important
 * name: asOptionalString(z.string().min(1))
 */

export function asOptionalString<T extends z.ZodTypeAny>(schema: T) {
  return schema.optional().or(emptyStringToUndefined);
}