import { GenericIDResponse } from '@/common/types';
import axios from '../axios/instance';
import { UpdateAddressDTO } from '@/common/dtos/update-address-dto';
import { ProjectRequestStatus } from '@/common/enums';
import { ProductSpecItem, ProjectRequestFull, ProjectRequestListItem } from './entities';

export type CreateProjectRequest = {
    title: string
    price: string
    companyName?: string | null
    address: UpdateAddressDTO,
    dealerId?: number | null
    product?: string | null
    specs: ProductSpecItem[]
    details?: string | null
    contract?: string | null
    files: string[]
}

export type EditProjectRequest  = CreateProjectRequest;

export type ListProjectRequests = {
    status?: ProjectRequestStatus
    dealerId?: number
    title?: string
    companyName?: string
    page: number
    perPage: number
}

export type dealerListProjectRequests = Omit<ListProjectRequests, 'dealerId'>

export type AdminListProjectRequestsResponse = {
    data: ProjectRequestListItem[]
    total: number
    success: boolean
}

export type DealerAcceptProjectRequestResponse = {
    projectId: number;
}

export type DealerListProjectRequestsResponse = AdminListProjectRequestsResponse;

export async function adminCreateProjectRequest(request: CreateProjectRequest) {
    const { data } = await axios.post<GenericIDResponse>('/admin/project-requests', request);
    return data;
}

export async function adminListProjectRequests(request: ListProjectRequests) {
    const { data } = await axios.get<AdminListProjectRequestsResponse>('/admin/project-requests', { params: request });
    return data;
}

export async function adminEditProjectRequest(id: unknown, request: EditProjectRequest) {
    const { data } = await axios.put<void>(`/admin/project-requests/${id}`, request);
    return data;
}

export async function adminFindProjectRequestById(id: unknown) {
    const { data } = await axios.get<ProjectRequestFull>(`/admin/project-requests/${id}`);
    return data;
}

export async function dealerListProjectRequests(request: ListProjectRequests) {
    const { data } = await axios.get<AdminListProjectRequestsResponse>('/dealers/project-requests', { params: request });
    return data;
}

export async function dealerFindProjectRequestById(id: unknown) {
    const { data } = await axios.get<ProjectRequestFull>(`/dealers/project-requests/${id}`);
    return data;
}

export async function dealerAcceptProjectRequests(id : number) {
    const { data } = await axios.patch<DealerAcceptProjectRequestResponse>(`/dealers/project-requests/${id}/accept`);
    return data;
}

export async function dealerRejectProjectRequests(id : number) {
    const { data } = await axios.patch<void>(`/dealers/project-requests/${id}/reject`);
    return data;
}