import axios from 'axios';
import { BASE_URL } from './constants';
import { transformError } from './errors';
import refreshAccessToken from './interceptors/refresh-access-token';
import attachAccessToken from './interceptors/attach-access-token';


// axios instance for making api calls
const instance = axios.create({
    baseURL: BASE_URL,
});

// axios has an issue
// it calls request interceptors in reverse order
// https://github.com/axios/axios/issues/5657
type SuccessInterceptor = Parameters<typeof instance.interceptors.request.use>[0];

const combinedInterceptor : SuccessInterceptor = async (config) => {
    return refreshAccessToken(config)
        .then(attachAccessToken);
}

instance.interceptors.request.use(combinedInterceptor);

declare module 'axios' {
    interface AxiosRequestConfig {
        skipAuth?: boolean; // defaults to false
    }
}

instance.interceptors.response.use(null, transformError);

export default instance;