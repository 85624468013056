import { Card, CardContent, CardHeader, CardTitle } from "@/common/components/ui/card";
import { DealerConfidentialWithRelated } from "@/dealer/entities/dealer";
import { PastProject } from "./past-project";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/common/components/ui/accordion";

export function DealerPastProjectsCard({ dealer, className }: { dealer: DealerConfidentialWithRelated; className?: string; }) {
    const hasProjects = (dealer?.pastProjects?.length || 0) > 0;

    return <Card className={className}>
        <CardHeader>
            <CardTitle>Past Projects</CardTitle>
        </CardHeader>
        <CardContent>
            {hasProjects && <Accordion type="single" collapsible>
                {
                    dealer
                        .pastProjects
                        .map((project, i) => (
                            <AccordionItem value={project.id.toString()}>
                                <AccordionTrigger>Project { i + 1 }</AccordionTrigger>
                                <AccordionContent>
                                    <PastProject key={project.id} project={project} />
                                </AccordionContent>
                            </AccordionItem>
                        ))
                }
            </Accordion>}
        </CardContent>
    </Card>;
}
