import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/common/components/ui/carousel";
import { AssetType } from "@/common/enums";
import { cn } from "@/common/utils/cn";
import { PastProjectWithAssets } from "@/dealer/entities/past-project";

export function PastProject({ project, className }: { project: PastProjectWithAssets, className?: string }) {
    const images = (project?.assetsJoin || [])
        .filter(j => j.asset.type === AssetType.Image)
        .map(j => j.asset.url);
    
    return <div key={project.id} className={cn("px-10" ,className)}>
        <p className="text-md mb-4">
            {project.description}
        </p>
        <ImagesCarousel  images={images}/>
    </div>
}

function ImagesCarousel({ images } : { images: string[] }) {
    return (
        <Carousel >
            <CarouselContent>
                {
                    images.map((image, i) => (
                        <CarouselItem key={i}>
                            <img
                                className="aspect-video rounded-sm" 
                                src={image}
                            />
                        </CarouselItem>
                    ))
                }
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
        </Carousel>
    )
}