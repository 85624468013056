import { projectsStatusColor, projectsStatusLabels, ProjectStatus } from "@/common/enums";
import { ProjectBasic } from "../api";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/components/ui/popover";
import { InfoIcon } from "lucide-react";

export function ProjectStatusWithReason({ project }: {
    project: ProjectBasic
}) {
    const showRejectReason = project.status === ProjectStatus.Rejected && project.rejectReason;
    const showResubmitReason = project.status === ProjectStatus.RequiresResubmission && project.resubmissionReason;
    const showReason = showRejectReason || showResubmitReason;

    return <div className="flex items-center gap-1">
        <div className="items-center flex gap-2">
            <span className="w-4 h-4 flex-none inline-block rounded-full" style={{ backgroundColor: projectsStatusColor[project.status] }}></span>
            {projectsStatusLabels[project.status]}
        </div>
        {
            showReason && (
                <Popover>
                    <PopoverTrigger>
                        <InfoIcon className="w-4 h-4 text-primary" />
                    </PopoverTrigger>
                    <PopoverContent>
                        { showRejectReason && <p>{ project.rejectReason }</p>}
                        { showResubmitReason && <p>{ project.resubmissionReason }</p>}
                    </PopoverContent>
                </Popover>
            )
        }
    </div>
}