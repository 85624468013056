import { Button } from "@/common/components/ui/button"
import { useState } from "react"
import { AcceptRequestPopup } from "./accept-request-popup";

export const AcceptRequestButton = ({ 
    requestId,
    onDone,
}: {
    requestId: number,
    onDone: () => any,
}) => {
    const [popupOpen, setPopupOpen] = useState(false);
    return <>
        <AcceptRequestPopup
            requestId={requestId}
            open={popupOpen} 
            onOpenChange={setPopupOpen} 
            onDone={onDone}
        />
        <Button 
            variant={'success'} 
            onClick={() => setPopupOpen(true)}>
            Accept
        </Button>
    </>
}