import { useAuthUser } from "@/auth/hooks/use-auth-user"
import { UserRole } from "@/common/enums";
import { DealerDashboardStats } from "../components/dealer-dashboard-stats";
import { AdminDashboardStats } from "../components/admin-dashboard-stats";

export function DashboardPage() {
    const { initialized, user } = useAuthUser();

    if (!initialized) {
        return null;
    }

    return <div>
        <h1 className="font-bold text-3xl">Dashboard</h1>
        <div className="mt-8">
            {
                (user?.role === UserRole.Admin) && (
                    <AdminDashboardStats />
                )
            }
            {
                (user?.role === UserRole.Dealer) && (
                    <DealerDashboardStats />
                )
            }
        </div>
    </div>
}