import { GenericIDResponse, GenericStatusResponse } from "@/common/types";
import axios from "../../axios/instance";

export type CreatePastProjectDTO = {
    description: string
    images: string[]
}

export async function addPastProject(dto: CreatePastProjectDTO) {
    const { data } = await axios.post<GenericIDResponse>('/dealer/past-projects', dto);
    return data;
}

export async function removePastProject(id: number) {
    const { data } = await axios.delete<GenericStatusResponse>(`/dealer/past-projects/${id}`);
    return data;
}

export async function editPastProject(id: number, dto: CreatePastProjectDTO) {
    const { data } = await axios.put<GenericStatusResponse>(`/dealer/past-projects/${id}`, dto);
    return data;
}