import { Button, ButtonProps } from "@/common/components/ui/button";
import { useState } from "react";
import { ChangeProjectStatusPopup } from "./change-project-status-popup";

export function ChangeProjectStatusButton({
    projectId,
    onDone,
    ...props
}: Omit<ButtonProps,  'onClick'> & {
    projectId: number,
    onDone: () => void,
}) {

    const [open, setOpen] = useState(false);

    return <>
        <ChangeProjectStatusPopup
            projectId={projectId}
            open={open}
            onOpenChange={setOpen}
            onSuccess={() => {
                setOpen(false);
                onDone();
            }}
        />
        <Button {...props} onClick={() => setOpen(true)}>
            Change Status
        </Button>
    </>
}