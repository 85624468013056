import axios from "../../axios/instance";

export type SendLinkRequest = {
    email: string;
}

export type ResetPasswordRequest = {
    token: string;
    password: string;
}


export async function sendResetPasswordEmail(request: SendLinkRequest) {
    await axios.post('/auth/send-password-reset-email', request, {
        skipAuth: true,
    });
}

export async function resetPassword(request: ResetPasswordRequest) {
    await axios.post('/auth/reset-password', request, {
        skipAuth: true,
    });
}