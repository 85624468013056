import { useDealer } from "@/dealer/hooks/use-dealer";
import { StepProps } from "../dealer-application-multistep-form";
import { DealerAddressForm } from "@/dealer/forms/dealer-address-form";
import { dealerAddressFormAction } from "@/dealer/forms/dealer-address-form-action";
import { NextPrevButtons } from "../next-prev-bottons";
import { StepContainer } from "../step-container";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { StepFormSkleton, StepSkeleton } from "../step-skleton";

export function AddressFormStep(props: StepProps) {
    const { data: dealer, error, isLoading } = useDealer();

    if (error) {
        return <AppErrorMessage error={error} />
    }

    if (isLoading) {
        return <StepFormSkleton />
    }

    return <>
        <div className="mt-10">
            <h1 className="text-2xl font-bold">
                Where is your company located?
            </h1>
        </div>
        <div className="mt-8">
            <DealerAddressForm
                initialValues={dealer?.address ? {
                    addressLine1: dealer.address.addressLine1!,
                    city: dealer.address.city!,
                    stateCode: dealer.address.stateCode!,
                    zipCode: dealer.address.zipCode!,
                    addressLine2: dealer.address.addressLine2 || '',
                } : undefined}
                onSubmit={async (values) => {
                    await dealerAddressFormAction(values);
                    props.goNext();
                }}
                renderSubmitButton={(submitting) => (
                    <NextPrevButtons {...props} submitting={submitting} />
                )}
            />
        </div>
    </>;
}