import { LOGO_PATH } from "@/brand/constants";
import { Outlet } from "react-router-dom";

export function AuthLayout() {
    return <div className="container">
        <div className="flex justify-center py-4">
            <img 
                src={LOGO_PATH}
                alt="logo"
                className="h-20" 
            />
        </div>
        <Outlet />
    </div>
}