import { Button, buttonVariants } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { Label } from "@/common/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/common/components/ui/radio-group";
import { ProjectRequestStatus, projectRequestStatusColor, projectRequestStatusLabels } from "@/common/enums";
import { asOptionalString } from "@/common/utils/zod";
import { DealerSelectInput } from "@/dealer/components/form-controls/dealer-select-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Edit2Icon, PlusIcon } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { adminListProjectRequests } from "../api";
import useSWR from "swr";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/components/ui/table";
import { TablePagination } from "@/common/components/ui/table-pagination";
import { formatDate } from "@/common/utils/date";
import { formatMoneyStr } from "@/common/utils/money";
import { ProjectRequestStatusComponent } from "../components/project-request-status-component";

export const filtersSchema = z.object({
    title: asOptionalString(z.string()),
    status: asOptionalString(z.nativeEnum(ProjectRequestStatus)),
    dealerId: asOptionalString(z.coerce.number()),
    companyName: asOptionalString(z.string()),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(10),
});

export type FiltersSchemaType = z.infer<typeof filtersSchema>;

export function AdminListProjectRequestsPage() {
    const [searchParams, _] = useSearchParams();

    /**
     * Parse filters.
     * 
     * default return value of `URLSearchParams.get` is `null`
     * but schema expects `undefined` not null.
     * 
     */
    const filters = filtersSchema.parse({
        title: searchParams.get('title') || undefined,
        status: searchParams.get('status') || undefined,
        dealerId: searchParams.get('dealerId') || undefined,
        companyName: searchParams.get('companyName') || undefined,
        page: searchParams.get('page') || undefined,
        perPage: searchParams.get('perPage') || undefined,
    });


    return <div>
        <div className="flex flex-col gap-8">
            <div className="flex flex-wrap justify-between">
                <h1 className="text-3xl font-bold">
                    Project Requests
                </h1>
                <div className="flex gap-2">
                    <Link to='create' className={buttonVariants({})}>
                        <PlusIcon className="w-5 h-5 mr-1" /> Create
                    </Link>
                </div>
            </div>
            <div className="bg-card p-5 rounded-md shadow-md">
                <AdminListProjectRequestPageFilters filters={filters} />
            </div>
            <div className="bg-card p-4 rounded-md shadow-md">
                <AdminListProjectRequestTable filters={filters} />
            </div>
        </div>
    </div>
}

function AdminListProjectRequestTable({ filters }: { filters: FiltersSchemaType }) {
    const {
        data,
        error,
        isLoading
    } = useSWR(['/admin/project-requests', filters], (key) => {
        let query = key[1];
        return adminListProjectRequests(query);
    });

    if (isLoading) {
        return <span>Loading...</span>
    }

    if (error) {
        return <AppErrorMessage error={error} />
    }

    return <div className="flex flex-col gap-3">
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>
                        ID
                    </TableHead>
                    <TableHead>
                        Title
                    </TableHead>
                    <TableHead>
                        Price
                    </TableHead>
                    <TableHead>
                        Dealer
                    </TableHead>
                    <TableHead>
                        Company
                    </TableHead>
                    <TableHead>
                        Status
                    </TableHead>
                    <TableHead>
                        Created At
                    </TableHead>
                    <TableHead>
                        Actions
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    data?.data.map(request => (
                        <TableRow key={request.id}>
                            <TableCell>
                                {request.id}
                            </TableCell>
                            <TableCell>
                                {request.title}
                            </TableCell>
                            <TableCell>
                                { formatMoneyStr(request.price, 0) }
                            </TableCell>
                            <TableCell>
                                {request.dealer?.businessName}
                            </TableCell>
                            <TableCell>
                                {request.companyName}
                            </TableCell>
                            <TableCell>
                                <ProjectRequestStatusComponent
                                    projectRequest={request}
                                />
                            </TableCell>
                            <TableCell>
                                { formatDate(request.createdAt) }
                            </TableCell>
                            <TableCell>
                                <div className="flex flex-wrap gap-2">
                                    <Link 
                                        className={buttonVariants({ variant: 'outline' })}
                                        to={`${request.id}/edit`}>
                                        Edit
                                    </Link>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
        <div className="flex justify-end">
            <TablePagination
                page={filters.page}
                perPage={filters.perPage}
                total={data?.total || 0}
            />
        </div>
    </div>
}

function AdminListProjectRequestPageFilters({ filters }: { filters: FiltersSchemaType }) {
    const [_, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const form = useForm<FiltersSchemaType>({
        resolver: zodResolver(filtersSchema),
        defaultValues: filters,
    });

    const handleSubmit: SubmitHandler<FiltersSchemaType> = (values) => {
        // values.prop could be undefined
        // but setSearchParams does not work with undefined
        setSearchParams({
            title: values.title || '',
            status: values.status || '',
            dealerId: values.dealerId || '',
            companyName: values.companyName || '',
        } as Record<string, string | string[]>);
    };

    const handleReset: React.EventHandler<React.MouseEvent> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSearchParams({});
        navigate(0);
    };

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex flex-col gap-8">
                <div>
                    <FormField
                        control={form.control}
                        name='status'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <RadioGroup 
                                        onValueChange={field.onChange}
                                        defaultValue={field.value || ''}
                                        className="cursor-pointer flex flex-wrap gap-4" 
                                        orientation="horizontal"
                                    >
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value="" id="all" />
                                            <Label htmlFor="all">All</Label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value={ProjectRequestStatus.Draft} id={ProjectRequestStatus.Draft} />
                                            <Label htmlFor={ProjectRequestStatus.Draft}>Draft</Label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value={ProjectRequestStatus.Pending} id={ProjectRequestStatus.Pending} />
                                            <Label htmlFor={ProjectRequestStatus.Pending}>Pending</Label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value={ProjectRequestStatus.Accepted} id={ProjectRequestStatus.Accepted} />
                                            <Label htmlFor={ProjectRequestStatus.Accepted}>Accepted</Label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value={ProjectRequestStatus.Rejected} id={ProjectRequestStatus.Rejected} />
                                            <Label htmlFor={ProjectRequestStatus.Rejected}>Rejected</Label>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="flex justify-between items-end flex-wrap gap-4">
                    <div>
                        <div className="flex flex-wrap gap-2">
                            <FormField
                                control={form.control}
                                name='dealerId'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Dealer</FormLabel>
                                        <FormControl>
                                            <DealerSelectInput
                                                className="w-52"
                                                value={field.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='title'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Project Title</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Title"
                                                {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='companyName'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Company Name</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Company Name"
                                                {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <Button type="submit" size='sm'>Search</Button>
                        <Button
                            onClick={handleReset}
                            variant='outline'
                            size='sm'
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </Form>
}