export enum UserRole {
    Dealer='dealer',
    Admin='admin',
}

export enum DealerApplicationStatus {
    Draft='draft',
    Submitted='submitted',
    Approved='approved',
    Rejected='rejected',
}

export const dealerApplicationStatusLabels : Record<DealerApplicationStatus, string> = {
    [DealerApplicationStatus.Draft]: 'Draft',
    [DealerApplicationStatus.Submitted]: 'Submitted',
    [DealerApplicationStatus.Approved]: 'Approved',
    [DealerApplicationStatus.Rejected]: 'Rejected'
};

export const dealerApplicationStatusColors : Record<DealerApplicationStatus, string> = {
    [DealerApplicationStatus.Draft]: '#4169E1',
    [DealerApplicationStatus.Submitted]: 'yellow',
    [DealerApplicationStatus.Approved]: 'green',
    [DealerApplicationStatus.Rejected]: 'red'
};

export enum AssetType {
    Image='image',
    PDF='pdf'
}

export enum ProjectRequestStatus {
    Draft = 'draft',
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected',
}

export const projectRequestStatusLabels : Record<ProjectRequestStatus, string> = {
    [ProjectRequestStatus.Draft]: 'Draft',
    [ProjectRequestStatus.Pending]: 'Pending',
    [ProjectRequestStatus.Accepted]: 'Accepted',
    [ProjectRequestStatus.Rejected]: 'Rejected'
};

export const projectRequestStatusColor : Record<ProjectRequestStatus, string> = {
    [ProjectRequestStatus.Draft]: '#4169E1',
    [ProjectRequestStatus.Pending]: 'yellow',
    [ProjectRequestStatus.Accepted]: 'green',
    [ProjectRequestStatus.Rejected]: 'red'
};

export enum ProjectStatus {
    InProgress = 'in_progress',
    Submitted = 'submitted',
    RequiresResubmission = 'requires_resubmission',
    Approved = 'approved',
    Rejected = 'rejected',
}

export const projectsStatusLabels : Record<ProjectStatus, string> = {
    [ProjectStatus.InProgress]: 'In Progress',
    [ProjectStatus.Submitted]: 'Submitted',
    [ProjectStatus.RequiresResubmission]: 'Requires Resubmission',
    [ProjectStatus.Approved]: 'Approved',
    [ProjectStatus.Rejected]: 'Rejected',
};

export const projectsStatusColor : Record<ProjectStatus, string> = {
    [ProjectStatus.InProgress]: '#4169E1',
    [ProjectStatus.Submitted]: 'yellow',
    [ProjectStatus.RequiresResubmission]: 'orange',
    [ProjectStatus.Approved]: 'green',
    [ProjectStatus.Rejected]: 'red',
};

export enum ProjectPaymentStatus {
    Paid = 'paid',
    Pending = 'pending',
}

export const projectPaymentStatusLabels : Record<ProjectPaymentStatus, string> = {
    [ProjectPaymentStatus.Paid]: 'Paid',
    [ProjectPaymentStatus.Pending]: 'Pending',
};

export const projectPaymentStatusColors : Record<ProjectPaymentStatus, string> = {
    [ProjectPaymentStatus.Paid]: 'green',
    [ProjectPaymentStatus.Pending]: 'yellow',
};