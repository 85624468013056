import { Skeleton } from "@/common/components/ui/skeleton";

export function StatsSkleton() {
    return <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {Array.from({ length: 4 }).map((_, i) => (
            <Skeleton key={i} className="col-span-1 bg-card w-full h-20" />
        ))}

    </div>;
}
