import {
    AuthStore,
    LocalStoragePersistance
} from 'reactive-auth-store';
import { Tokens } from './api/login';
import { UserInfo } from './user';


let store: AuthStore<UserInfo, Tokens> | null = null;

const AUTH_STORAGE_KEY = '__auth__'

// function to lazily create store when required
export default function getStore() {
    if (store === null) {
        store = new AuthStore(
            // create persistor to save state
            new LocalStoragePersistance(AUTH_STORAGE_KEY),
            // a function to compares user equality
            (userA, userB) => (userA?.id || null) === (userB?.id || null)
        )
        store.setMaxListeners(1000);
    }
    return store;
}