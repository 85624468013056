import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon, TrashIcon } from "lucide-react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { StateSelectFormControl } from "../../common/components/form-controls/state-select-form-control";
import { useState } from "react";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { labelVariants } from "@/common/components/ui/label";
import { cn } from "@/common/utils/cn";

const licensesFormSchema = z.object({
    licenses: z.array(
        z.object({
            id: z.optional(z.number()),
            stateCode: z.string().min(1, {
                message: 'Please select state'
            }),
            number: z.string().min(1, {
                message: 'Please enter license number',
            })
        })
    )
})

export type LicensesFormSchemaType = z.infer<typeof licensesFormSchema>;

export function DealerLicensesForm({
    onSubmit,
    defaultValues,
    renderSubmitButton,
    fieldsContainerClassName,
}: {
    onSubmit: (values: LicensesFormSchemaType) => Promise<any>,
    defaultValues?: Partial<LicensesFormSchemaType>,
    renderSubmitButton?: (submitting: boolean) => React.ReactElement,
    fieldsContainerClassName?: string,
}) {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<LicensesFormSchemaType>({
        resolver: zodResolver(licensesFormSchema),
        defaultValues: defaultValues,
    });

    const { fields, append, remove } = useFieldArray({
        name: 'licenses',
        control: form.control,
    });

    const handleAddRow: React.MouseEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        append({
            number: '',
            stateCode: '',
        })
    }

    const handleSubmit: SubmitHandler<LicensesFormSchemaType> = async (values) => {
        try {
            setSubmitting(true);
            await onSubmit(values);
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
                <AppErrorMessage error={error} />
                <div className={cn("flex flex-col gap-4", fieldsContainerClassName)}>
                    {/* labels only for first row */}
                    <div className="flex gap-4">
                        <div className="w-40">
                            <span className={labelVariants()}>State</span>
                        </div>
                        <div>
                            <span className={labelVariants()}>License Number</span>
                        </div>
                    </div>
                    {
                        fields.map((field, index) => (
                            <div
                                key={field.id}
                                className="flex gap-4 items-start">

                                <FormField
                                    control={form.control}
                                    name={`licenses.${index}.stateCode`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <StateSelectFormControl
                                                className="w-40"
                                                field={field}
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name={`licenses.${index}.number`}
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button
                                    variant='outline'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        remove(index);
                                    }}
                                    disabled={index <= 0}
                                >
                                    <TrashIcon size={16} />
                                </Button>
                                
                            </div>
                        ))
                    }
                    <div>
                        <Button
                            variant={'success'}
                            size='sm'
                            onClick={handleAddRow}
                        >
                            <PlusIcon size={16} /> Add
                        </Button>
                    </div>
                </div>

                <div className={cn({ "text-right": !renderSubmitButton })}>
                    {
                        !!renderSubmitButton
                            ? renderSubmitButton(submitting)
                            : <Button type="submit" size='lg' disabled={submitting}>
                                Submit
                            </Button>
                    }
                </div>
            </form>
        </Form>
}


