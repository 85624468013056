import { Button } from "@/common/components/ui/button";
import { useState } from "react";
import { SubmitForApprovalPopup } from "./submit-for-approval-popup";

export function SubmitForApprovalButton({
    projectId,
    onDone,
}: {
    projectId: number,
    onDone: () => void,
}) {

    const [open, setOpen] = useState(false);

    return <>
        <SubmitForApprovalPopup
            projectId={projectId}
            open={open}
            onOpenChange={setOpen}
            onSuccess={() => {
                setOpen(false);
                onDone();
            }}
        />
        <Button variant={'success'} onClick={() => setOpen(true)}>
            Submit
        </Button>
    </>
}