import { buttonVariants } from "@/common/components/ui/button";
import { useState } from "react";
import { Link } from "react-router-dom";
import { cn } from "@/common/utils/cn";
import { ForgotPasswordForm } from "../components/forgot-password-form";
import { LOGO_PATH } from "@/brand/constants";

export function ForgotPassword() {
    const [sent, setSent] = useState(false);

    return <div className="flex justify-center items-start lg:items-center mt-16">
        <div className="bg-card shadow-lg rounded-lg w-full max-w-lg my-8 px-2">
            <div className={cn("mt-16 mb-14", { "mt-14": sent })}>
                {/* <div className='flex justify-center'>
                <img className='w-[300px] h-auto' src={LOGO_PATH} />
            </div> */}
                <h1 className="text-3xl text-center font-bold">
                    {
                        sent
                            ? 'Check email'
                            : 'Forgot Password?'
                    }
                </h1>
                <p className="mt-4 text-foreground/50 text-center">
                    {
                        sent
                            ? 'Check your email for password reset link.'
                            : 'Please enter the email address.'
                    }

                </p>
            </div>
            {
                !sent && (
                    <>
                        <div className="p-3 md:px-8">
                            <ForgotPasswordForm onSent={()=> setSent(true)} />
                        </div>
                        <div className='text-center mt-0 my-8'>
                            <Link
                                to="/auth/login"
                                className={
                                    cn(
                                        buttonVariants({ variant: 'link', className: 'text-base px-2' }),
                                        "text-foreground/50"
                                    )
                                }>
                                Back to login
                            </Link>
                        </div>
                    
                    </>
                )
            }
        </div>
    </div>
}