import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/common/components/ui/select";
import { Textarea } from "@/common/components/ui/textarea";
import { projectsStatusLabels, ProjectStatus } from "@/common/enums";
import { asOptionalString } from "@/common/utils/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const formSchema = z.object({
    status: z.nativeEnum(ProjectStatus),
    rejectReason: asOptionalString(z.string()),
    resubmissionReason: asOptionalString(z.string()),
}).refine(
    // rejectReason must be specified when changing status to Rejected 
    (data) => data.status !== ProjectStatus.Rejected || String(data.rejectReason).trim().length > 0,
    {
        message: 'reject reason is required',
        path: ['rejectReason'],
    }
).refine(
    // resubmissionReason must be specified when changing status to RequiresResubmission 
    (data) => data.status !== ProjectStatus.RequiresResubmission || String(data.resubmissionReason).trim().length > 0,
    {
        message: 'resubmission reason is required',
        path: ['resubmissionReason'],
    }
);

export type FormSchemaType = z.infer<typeof formSchema>;

export const ChangeProjectStatusForm = ({
    onSubmit,
}: {
    onSubmit: (values: FormSchemaType) => Promise<void>
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<FormSchemaType>({
        resolver: zodResolver(formSchema),
    });

    const handleSubmit = async (values: FormSchemaType) => {
        try {
            setError(null);
            setSubmitting(true);
            await onSubmit(values);
        } catch (e) {
            setError(e)
        } finally {
            setSubmitting(false);
        }
    }

    const status = form.watch('status');
    const showRejectReason = status === ProjectStatus.Rejected;
    const showResubmissionReason = status === ProjectStatus.RequiresResubmission;

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} className="mb-4" />
            <div className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="status"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Status</FormLabel>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Project Status" />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {
                                        [ ProjectStatus.Approved, ProjectStatus.Rejected, ProjectStatus.RequiresResubmission ].map(
                                            (status) => (
                                                <SelectItem key={status} value={status}>
                                                    { projectsStatusLabels[status] }
                                                </SelectItem>
                                            )
                                        )
                                    }
                                </SelectContent>
                            </Select>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {
                    showRejectReason && (
                        <FormField
                            control={form.control}
                            name="rejectReason"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Reason*</FormLabel>
                                    <FormControl>
                                        <Textarea {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    )
                }
                {
                    showResubmissionReason && (
                        <FormField
                            control={form.control}
                            name="resubmissionReason"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Reason*</FormLabel>
                                    <FormControl>
                                        <Textarea {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    )
                }
                <Button type="submit" size='lg' disabled={submitting}>
                    Submit
                </Button>
            </div>
        </form>
    </Form>
}