import { Form, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { uploadedAssetSchema } from "@/uploaded-assets/entities/uploaded-asset";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { UploadFormControl } from "@/common/components/form-controls/upload-form-control";
import { Button } from "@/common/components/ui/button";

export const formSchema = z.object({
    files: z.array(uploadedAssetSchema).min(3),
});

export type FormSchemaType = z.infer<typeof formSchema>;

export type FormSchemaInitialValuesType = Required<FormSchemaType>;

export function SubmitForApprovalForm({
    initialValues,
    onDone,
    onCancel
} : {
    initialValues?: FormSchemaInitialValuesType,
    onDone: (values: FormSchemaType) => Promise<void>
    onCancel: () => void | Promise<void>,
}) {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm({
        defaultValues: initialValues,
        resolver: zodResolver(formSchema),
    });

    async function handleSubmit(values: FormSchemaType) {
        try {
            setError(null);
            setSubmitting(true);
            await onDone(values);
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-8">
            <AppErrorMessage error={error} />
            <div className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name={`files`}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                Images & PDFS
                            </FormLabel>
                            <UploadFormControl
                                allowAssets={true}
                                field={field}
                                dashboardProps={{
                                    height: '300px',
                                    width: 'auto',
                                }}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="flex flex-col sm:flex-row">
                <Button 
                    disabled={submitting}
                    type="submit" 
                    variant="default"
                >
                    Submit
                </Button>
                <Button
                    disabled={submitting}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCancel();
                    }} 
                    variant="secondary"
                >
                    Cancel
                </Button>
            </div>
        </form>
    </Form>
}