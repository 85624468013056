import { FileIcon } from "lucide-react";
import { AssetType } from "@/common/enums";
import { UploadedAssetBasic } from "@/uploaded-assets/entities/uploaded-asset";
import { buttonVariants } from "@/common/components/ui/button";

export function UploadedAssetsGrid({ assets }: { assets: UploadedAssetBasic[]; }) {
    return <div className="flex flex-col gap-4 lg:grid lg:grid-cols-5 lg:gap-8">
        {assets.map(asset => (
            <div key={asset.id} className="lg:col-span-1 ">
                <UploadedAssetGridItem asset={asset} />
            </div>
        ))}
    </div>;
}

function UploadedAssetGridItem({ asset }: { asset: UploadedAssetBasic; }) {
    const isImage = asset.type === AssetType.Image;
    const isPDF = asset.type === AssetType.PDF;
    return <div className="flex flex-row lg:flex-col gap-4">
        <div className="flex-none bg-slate-200 w-20 lg:w-full aspect-square relative overflow-clip rounded">
            {isImage && <img className="absolute inset-0 w-full h-full object-cover" src={asset.url} />}
            {!isImage && <div className="absolute inset-0 w-full h-full flex justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                    <FileIcon size={32} />
                    {isPDF ? 'PDF' : 'Other'}
                </div>
            </div>}
        </div>
        <div className="p-2 lg:p-0 flex-1 flex-col gap-2 overflow-hidden">
            <p className="max-w-full truncate text-foreground/80" title={asset.filename}>
                {asset.filename}
            </p>
            <a href={asset.url} className={buttonVariants({ variant: 'outline', className: "max-w-32 lg:max-w-none mt-3" })} target="__blank">
                Download
            </a>
        </div>
    </div>;
}
