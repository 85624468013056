import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOffIcon, LockIcon, MailIcon, UserIcon } from "lucide-react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { signup } from "../api/signup";
import { toast } from "sonner";
import { passwordSchema } from "../utils";

const formSchema = z.object({
    name: z.string()
        .min(1, 'name is required'),
    email: z.string()
        .min(1)
        .email(),
    password: passwordSchema,
});

type FormSchemaType = z.infer<typeof formSchema>;

export function SignupForm() {
    const [error, setError] = useState<any>(null);
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const form = useForm<FormSchemaType>({
        resolver: zodResolver(formSchema),
    });

    const handleSubmit: SubmitHandler<FormSchemaType> = async (values) => {
        try {

            setError(null);
            setSubmitting(true);

            await signup(values);
            toast.success('Signup successful, login to continue');            
            navigate('/auth/login');

        } catch (e) {

            setError(e);

        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
        <AppErrorMessage className="my-4" error={error} />
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex flex-col gap-8">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Name*</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        className='pl-10'
                                        placeholder="Name"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <UserIcon />
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email*</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        className='pl-10'
                                        placeholder="Email"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <MailIcon />
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Password*</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        autoComplete="new-password"
                                        type={showPassword ? 'text' : 'password'}
                                        className='pl-10 pr-10'
                                        placeholder="Password"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <LockIcon />
                                    </div>
                                    <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowPassword(s => !s)
                                            }}
                                            size='iconSm'
                                            variant='ghost'>
                                            {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                        </Button>
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className='text-center'>
                    <Button
                        disabled={submitting}
                        type="submit"
                        size='lg'
                        className='text-base font-normal px-16'>
                        Sign Up
                    </Button>
                </div>
            </div>
        </form>
    </Form>
}