import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Input } from "@/common/components/ui/input";

import { EyeIcon, EyeOffIcon, LockIcon, MailIcon } from "lucide-react";
import { useState } from "react";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { passwordSchema } from "../utils";
import { toast } from "sonner";
import { resetPassword } from "../api/reset-password";

const formSchema = z.object({
    password: passwordSchema,
    confirm: z.string(),
});

const formSchemaRefined = formSchema
    .refine((value) => value.password === value.confirm, {
        message: 'passwords dont match',
        path: ['confirm']
    });

type FormSchemaType = z.infer<typeof formSchema>;

export function ResetPasswordForm({ token } : { token: string }) {

    const [error, setError] = useState<unknown>(null);
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const navigate = useNavigate();

    const handleResetPassword = async (request : FormSchemaType) => {
        debugger
        await resetPassword({ token, password: request.password });
        debugger
        toast.success("password changed login to continue");
        navigate('/auth/login');
    }

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchemaRefined),
        defaultValues: {
            password: '',
            confirm: '',
        }
    });

    const handleSubmit = async (
        values: z.infer<typeof formSchema>,
    ): Promise<any> => {
        setError(null);
        setSubmitting(true);

        try {
            await handleResetPassword(values);
        } catch (e) {
            debugger
            setError(e);
        } finally {
            setSubmitting(false);
        }
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} className="mb-4" />

            <div className="grid grid-cols-1 gap-8">
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Password*</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        autoComplete="new-password"
                                        type={showPassword ? 'text' : 'password'}
                                        className='pl-10 pr-10'
                                        placeholder="Password"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <LockIcon />
                                    </div>
                                    <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowPassword(s => !s)
                                            }}
                                            size='iconSm'
                                            variant='ghost'>
                                            {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                        </Button>
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="confirm"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Password*</FormLabel>
                            <FormControl>
                                <div className="relative">
                                    <Input
                                        type={showConfirm ? 'text' : 'password'}
                                        className='pl-10 pr-10'
                                        placeholder="Password"
                                        {...field}
                                    />
                                    <div className="absolute top-0 left-2 bottom-0 flex justify-center items-center text-input">
                                        <LockIcon />
                                    </div>
                                    <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowConfirm(s => !s)
                                            }}
                                            size='iconSm'
                                            variant='ghost'>
                                            {showConfirm ? <EyeOffIcon /> : <EyeIcon />}
                                        </Button>
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            
                <div className='text-center mt-4'>
                    <Button
                        disabled={submitting}
                        type="submit"
                        size='lg'
                        className='text-base font-normal px-16'>
                        Continue
                    </Button>
                </div>
            </div>
        </form>
    </Form>
}