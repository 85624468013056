import { useAuthUser } from "@/auth/hooks/use-auth-user";
import { Avatar, AvatarFallback } from "@/common/components/ui/avatar";
import { Button } from "@/common/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/common/components/ui/dropdown-menu";
import { Skeleton } from "@/common/components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { getInitials } from "../utils";
import { logout } from "@/auth/utils";
import { mutate } from "swr";


export function UserNav({
    ...props
}: React.HTMLAttributes<HTMLElement>) {
    const { user, initialized } = useAuthUser();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        mutate(() => true, undefined, false);
        navigate('/auth/login', { replace: true });
    };

    if (!initialized || !user) {
        return <Skeleton className="h-8 w-8 rounded-full" />;
    }

    return <div {...props}>
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                    <Avatar className="h-8 w-8">
                        <AvatarFallback className="bg-primary/50 text-primary-foreground">
                            {getInitials(user.name)}
                        </AvatarFallback>
                    </Avatar>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">{user.name}</p>
                        <p className="text-xs leading-none text-muted-foreground">
                            {user.email}
                        </p>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onSelect={() => {
                    handleLogout();
                }}>
                    Log out
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    </div>;
}
