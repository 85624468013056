import { GenericStatusResponse } from "@/common/types";
import axios from "../../axios/instance";

export type SignupRequest = {
    name: string;
    email: string;
    password: string;
}

export async function signup(request: SignupRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/signup', request, {
        skipAuth: true
    })
    return data;
}