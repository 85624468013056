// zod form (form schema)
// take default params
// build form
// call onSubmit callback

import { StateSelectFormControl } from "@/common/components/form-controls/state-select-form-control";
import { UploadFormControl } from "@/common/components/form-controls/upload-form-control";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { Textarea } from "@/common/components/ui/textarea";
import { asOptionalString } from "@/common/utils/zod";
import { DealerSelectInput } from "@/dealer/components/form-controls/dealer-select-input";
import { DealerAddressForm } from "@/dealer/forms/dealer-address-form";
import { uploadedAssetSchema } from "@/uploaded-assets/entities/uploaded-asset";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon, TrashIcon } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

const addressSchema = z.object({
    
    addressLine1: z.string()
        .min(1, { message: 'Address is required' })
        .max(255, { message: 'Address can be max 255 characters' }),

    addressLine2: asOptionalString(z.string()
        .min(1)
        .max(255)),

    stateCode: z.string().min(2, { message: 'required' }),

    city: z.string().min(1, 'required'),

    zipCode: z.string().min(1, 'required'),
});

export const projectRequestFormSchema = z.object({
    dealerId: z.number().nullable().default(null),
    title: z.string(),
    companyName: z.string().nullable().default(null),
    price: z.string().refine((val) => {
        let num = parseFloat(val);
        return !isNaN(num) && num > 0;
    }, {
        message: 'positive number'
    }),
    address: addressSchema,
    product: z.string().nullable().default(null),
    specs: z.array(z.object({
        key: z.string(),
        value: z.string(),
    })),
    details: z.string().nullable().default(null),
    contract: z.string().nullable().default(null),
    files: z.array(uploadedAssetSchema),
});

export type ProjectRequestFormSchemaType = z.infer<typeof projectRequestFormSchema>;


// Make every property required because we cannot use undefined as value for controlled inputs
// Removing this would make allow undefined 
// which could lead to uncontrolled input being converted to controlled input.
export type ProjectRequestFormSchemaInitialValuesType = Required<ProjectRequestFormSchemaType>;

export function ProjectRequestForm({
    initialValues,
    onSubmit
}: {
    initialValues?: ProjectRequestFormSchemaInitialValuesType,
    onSubmit: (data: ProjectRequestFormSchemaType) => Promise<void>,
}) {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<ProjectRequestFormSchemaType>({
        resolver: zodResolver(projectRequestFormSchema),
        defaultValues: initialValues,
    });

    const { fields, append, remove } = useFieldArray({
        name: 'specs',
        control: form.control,
    });

    const handleAddRow: React.MouseEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        append({
            key: '',
            value: '',
        })
    }

    const handleSubmit = async (data: ProjectRequestFormSchemaType) => {
        try {
            console.log("try");
            setError(null);
            setSubmitting(true);
            await onSubmit(data);
            console.log("submitted");
        } catch (e) {
            setError(e);
            console.log("errr", e);
        } finally {
            console.log("finally");
            setSubmitting(false);
        }
    }


    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} />
            <div className="flex flex-col gap-4 mt-3">
                <FormField
                    name='dealerId'
                    render={({ field }) => (
                        <FormItem className="mb-4">
                            <FormLabel>Dealer</FormLabel>
                            <FormControl>
                                <DealerSelectInput
                                    value={field.value || undefined}
                                    onChange={(value) => {
                                        field.onChange(value || null);
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <hr />
                <div className="grid grid-cols-2 gap-4">
                    <FormField
                        name='title'
                        render={({ field }) => (
                            <FormItem className="col-span-2 md:col-span-1">
                                <FormLabel>Title*</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        name='price'
                        render={({ field }) => (
                            <FormItem className="col-span-2 md:col-span-1">
                                <FormLabel>Price*</FormLabel>
                                <FormControl>
                                    <Input type="number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <FormField
                    name='company'
                    render={({ field }) => (
                        <FormItem className="col-span-2 md:col-span-1">
                            <FormLabel>Company</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <FormField
                            control={form.control}
                            name="address.addressLine1"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Address*</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormField
                            control={form.control}
                            name="address.addressLine2"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Address Line 2</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormField
                            control={form.control}
                            name="address.city"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>City*</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="col-span-1">
                        <FormField
                            control={form.control}
                            name="address.stateCode"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>State*</FormLabel>
                                    <FormControl>
                                        <StateSelectFormControl field={field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="col-span-1">
                        <FormField
                            control={form.control}
                            name="address.zipCode"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Zipcode*</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                {/* Project specs begin */}
                <div className="flex flex-col gap-4">
                    <h3>Product Specs</h3>
                    <FormField
                        name='product'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Product Name</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    {
                        fields.map((field, index) => (
                            <div key={field.id}>
                                <div className="flex flex-row gap-4">
                                    <div className="flex-1">
                                        <FormField
                                            control={form.control}
                                            name={`specs.${index}.key`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    {
                                                        index === 0 && (
                                                            <FormLabel>Field</FormLabel>
                                                        )
                                                    }
                                                    <FormControl>
                                                        <Input {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <FormField
                                            control={form.control}
                                            name={`specs.${index}.value`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    {
                                                        index === 0 && (
                                                            <FormLabel>Value</FormLabel>
                                                        )
                                                    }
                                                    <FormControl>
                                                        <Input {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div className="self-end">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                remove(index);
                                            }}
                                            size='icon'
                                            variant='outline'>
                                            <TrashIcon size={16} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="mt-2 text-center">
                        <Button
                            className="w-full"
                            variant={'outline'}
                            size='lg'
                            onClick={handleAddRow}
                        >
                            <PlusIcon size={16} /> Add Spec
                        </Button>
                    </div>
                </div>
                {/* Project specs end */}

                {/* image assets */}
                <FormField
                    control={form.control}
                    name={`files`}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                Images & PDFS
                            </FormLabel>
                            <UploadFormControl
                                allowAssets={true}
                                field={field}
                                dashboardProps={{
                                    height: '280px',
                                }}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />

                {/* details */}
                <FormField
                    control={form.control}
                    name={`details`}
                    render={({ field }) => {
                        const { onChange, value, ...rest } = field;
                        return <FormItem>
                            <FormLabel>Details</FormLabel>
                            <FormControl>
                                {/* textarea value does not accept null */}
                                {/* form schema value does not accept undefined */}
                                <Textarea
                                    {...rest}
                                    rows={10}
                                    value={value || undefined}
                                    onChange={(v) => {
                                        onChange(v.target.value || null);
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    }}
                />

                {/* contract */}
                <FormField
                    control={form.control}
                    name={`contract`}
                    render={({ field }) => {
                        const { onChange, value, ...rest } = field;
                        return <FormItem>
                            <FormLabel>Contract</FormLabel>
                            <FormControl>
                                {/* textarea value does not accept null */}
                                {/* form schema value does not accept undefined */}
                                <Textarea
                                    {...rest}
                                    rows={10}
                                    value={value || undefined}
                                    onChange={(v) => {
                                        onChange(v.target.value || null);
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    }}
                />

                <AppErrorMessage error={error} />

                <div className="text-right mt-4">
                    <Button type="submit" disabled={submitting}>
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    </Form>
}