import { createOrUpdateDealer } from "../api/create-or-update-dealer";
import { DealerFormSchemaType } from "./dealer-basic-info-form";

/**
 * 
 * Separate handler decouples form from it's submission logic.
 * 
 */
export async function dealerBasicInfoFormAction(data : DealerFormSchemaType) {
    await createOrUpdateDealer({
        ...data,
    });
}