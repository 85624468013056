import { UserNav } from "@/app/components/user-nav";
import { LOGO_PATH } from "@/brand/constants";

export function StepLayout({ children, ...props } : React.HTMLAttributes<HTMLDivElement>) {
    return <div {...props}>
        <div className="bg-card">
            <div className="container">
                <div className="flex h-24 items-center px-4">
                    <img
                        src={LOGO_PATH}
                        alt='logo'
                        className="h-20"
                    />
                    <UserNav className="ml-auto" />
                </div>
            </div>
        </div>
        { children }
    </div>
}