import { InternalAxiosRequestConfig } from 'axios';
import { refreshToken } from '../../auth/api/refresh-token';
import { Mutex } from 'async-mutex';
import getStore from '../../auth/store';
import { getAccessTokenExpiry } from '../token-utils';

const mutex = new Mutex();

export default async function refreshAccessToken(config : InternalAxiosRequestConfig<any> ) {

    // some api endpoints don't need access token
    if (config.skipAuth) {
        return config;
    }

    return await mutex.runExclusive(async () => {

        const store = getStore();
        const tokens = store.getTokens();

        // user is not logged in
        if (tokens === null) {
            return config;
        }

        // access token expiration date is not known
        const accessTokenExpiry = getAccessTokenExpiry(tokens);
        if (accessTokenExpiry === null) {
            return config;
        }
    
        // access token is still valid
        let accessTokenExpired = accessTokenExpiry.getTime() < (new Date()).getTime();
        if (!accessTokenExpired) {
            return config;
        }

        // access token has expired refresh it
        const newAccessToken = await refreshToken();
        tokens.accessToken = newAccessToken.accessToken;
        tokens.accessTokenExpiryTs = newAccessToken.accessTokenExpiryTs;
        await store.setTokens(tokens);
    
        // proceed after refrshing access token 
        return config;
    })
};