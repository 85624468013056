import { DealerConfidential, DealerConfidentialWithRelated } from '@/dealer/entities/dealer';
import axios from '../axios/instance';
import { DealerApplicationStatus } from "@/common/enums";
import { GenericStatusResponse } from '@/common/types';

export type DealersListRequest = {
    applicationStatus?: DealerApplicationStatus;
    businessName?: string;
    id?: number;
    page: number;
    perPage: number;
}

export type DealersListResponse = {
    data: DealerConfidential[]
    total: number
    success: boolean
}

export async function listDealers(request: DealersListRequest) {
    const { data } = await axios.get<DealersListResponse>('/admin/dealers', { params: request });
    return data;
}

export async function findById(id : unknown) {
    const { data } = await axios.get<DealerConfidentialWithRelated>(`/admin/dealers/${id}`);
    return data;
}

export async function approveDealer(id: unknown) {
    const { data } = await axios.post<GenericStatusResponse>(`admin/dealers/${id}/approve`);
    return data;
}

export async function rejectDealer(id: unknown) {
    const { data } = await axios.post<GenericStatusResponse>(`admin/dealers/${id}/reject`);
    return data;
}