import { InternalAxiosRequestConfig } from 'axios';
import getStore from '../../auth/store';

export default async function attachAccessToken(config : InternalAxiosRequestConfig<any> ) {

    // some api endpoints don't need access token
    if (config.skipAuth) {
        return config;
    }

    // get access token expiry date
    let tokens = getStore().getTokens();
    let accessToken = tokens?.accessToken || null;

    // set authorization header
    if (accessToken) {
        config.headers = config.headers || {};
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
};
