import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { projectsStatusColor, projectsStatusLabels } from "@/common/enums";
import { Loader2Icon } from "lucide-react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { adminFindProjectById } from "../api";
import { ChangeProjectStatusButton } from "../components/change-project-status-button";
import { ProjectInfo } from "../components/project-info";
import { canAddPayment } from "../utils";
import { AddPaymentButton } from "../components/add-payment-button";

export function AdminViewProjectPage() {
    const { id } = useParams();
    const {
        data: project,
        isLoading,
        error,
        mutate,
    } = useSWR(['/admin/projects/', id], (key) => {
        const [_, id] = key;
        return adminFindProjectById(id);
    });

    if (isLoading) {
        return <div>
            <div className="flex justify-center py-4">
                <Loader2Icon className="text-primary w-12 h-12 animate-spin" />
            </div>
        </div>
    }

    if (error) {
        return <div>
            <AppErrorMessage error={error} />
        </div>
    }

    return <div className="bg-card rounded p-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <h1 className="text-xl">
                {project?.title}
            </h1>
            <div className="flex flex-row gap-2">
                {
                    project && (
                        <ChangeProjectStatusButton
                            projectId={project.id}
                            variant={'outline'}
                            onDone={() => {
                                mutate();
                            }}
                        />
                    )
                }
                {
                    project && canAddPayment(project) && (
                        <AddPaymentButton
                            variant={'outline'}
                            projectId={project.id}
                            onDone={() => {
                                mutate();
                            }}
                        />
                    )
                }
            </div>
        </div>
        <div className="mt-5">
            {
                project && (
                    <ProjectInfo  project={project}/>
                )
            }
        </div>
    </div>
}