import { useDealer } from "@/dealer/hooks/use-dealer";
import { StepProps } from "../dealer-application-multistep-form";
import { DealerBasicInfoForm } from "@/dealer/forms/dealer-basic-info-form";
import { EmployeeCountValues } from "@/dealer/api/create-or-update-dealer";
import { dealerBasicInfoFormAction } from "@/dealer/forms/dealer-basic-info-form-action";
import { NextPrevButtons } from "../next-prev-bottons";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { StepFormSkleton, StepSkeleton } from "../step-skleton";

export function BasicInfoFormStep(props: StepProps) {
    const { data: dealer, error, isLoading } = useDealer();

    if (error) {
        return <AppErrorMessage error={error} />
    }

    if (isLoading) {
        return <StepFormSkleton />
    }

    return <>
        <div className="mt-10">
            <h1 className="text-2xl font-bold">
                Tell us a bit about your company.
            </h1>
        </div>
        <div className="mt-8">
            <DealerBasicInfoForm
                initialValues={dealer ? {
                    businessName: dealer.businessName,
                    employeesCount: dealer.employeesCount || EmployeeCountValues[0],
                    expertiseSummary: dealer.expertiseSummary || '',
                    mobilePhone: dealer.mobilePhone || '',
                    officePhone: dealer.officePhone || '',
                    representativeName: dealer.representativeName || '',
                    taxId: dealer.taxId || '',
                    yearsOfExperience: dealer.yearsOfExperience || 1,
                } : undefined}
                onSubmit={async (data) => {
                    await dealerBasicInfoFormAction(data);
                    props.goNext();
                }}
                renderSubmitButton={(submitting) => (
                    <NextPrevButtons {...props} submitting={submitting} />
                )}
            />
        </div>
    </>;
}

