import { GenericIDResponse, GenericStatusResponse } from "@/common/types";
import axios from "../../axios/instance";

export type CreateLicenseDTO = {

    stateCode: string;

    countryCode: string;

    number: string;
}

export async function addLicense(dto: CreateLicenseDTO) {
    const { data } = await axios.post<GenericIDResponse>('/dealer/licenses', dto);
    return data;
}

export async function removeLicense(id: number) {
    const { data } = await axios.delete<GenericStatusResponse>(`/dealer/licenses/${id}`);
    return data;
}

export async function editLicense(id: number, dto: CreateLicenseDTO) {
    const { data } = await axios.put<GenericStatusResponse>(`/dealer/licenses/${id}`, dto);
    return data;
}