import { Button, buttonVariants } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { Label } from "@/common/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/common/components/ui/radio-group";
import { ProjectPaymentStatus, ProjectStatus, projectPaymentStatusLabels, projectsStatusLabels } from "@/common/enums";
import { asOptionalString } from "@/common/utils/zod";
import { DealerSelectInput } from "@/dealer/components/form-controls/dealer-select-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { adminListProjects } from "../api";
import useSWR from "swr";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/components/ui/table";
import { TablePagination } from "@/common/components/ui/table-pagination";
import { formatDate } from "@/common/utils/date";
import { formatMoneyStr } from "@/common/utils/money";
import { ChangeProjectStatusButton } from "../components/change-project-status-button";
import { ProjectStatusWithReason } from "../components/project-status-with-reason";
import { AddPaymentButton } from "../components/add-payment-button";
import { canAddPayment } from "../utils";
import { Select, SelectTrigger, SelectContent, SelectValue, SelectItem } from "@/common/components/ui/select";
import { PaymentStatusComponent } from "../components/payment-status-component";

export const filtersSchema = z.object({
    title: asOptionalString(z.string()),
    status: asOptionalString(z.nativeEnum(ProjectStatus)),
    dealerId: asOptionalString(z.coerce.number()),
    paymentStatus: asOptionalString(z.nativeEnum(ProjectPaymentStatus)),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(10),
});

export type FiltersSchemaType = z.infer<typeof filtersSchema>;

export function AdminListProjectsPage() {
    const [searchParams, _] = useSearchParams();

    /**
     * Parse filters.
     * 
     * default return value of `URLSearchParams.get` is `null`
     * but schema expects `undefined` not null.
     * 
     */
    const filters = filtersSchema.parse({
        title: searchParams.get('title') || undefined,
        status: searchParams.get('status') || undefined,
        dealerId: searchParams.get('dealerId') || undefined,
        paymentStatus: searchParams.get('paymentStatus') || undefined,
        page: searchParams.get('page') || undefined,
        perPage: searchParams.get('perPage') || undefined,
    });


    return <div>
        <div className="flex flex-col gap-8">
            <div className="flex flex-wrap justify-between">
                <h1 className="text-3xl font-bold">
                    Projects
                </h1>
            </div>
            <div className="bg-card p-5 rounded-md shadow-md">
                <AdminListProjectsPageFilters filters={filters} />
            </div>
            <div className="bg-card p-4 rounded-md shadow-md">
                <AdminListProjectsTable filters={filters} />
            </div>
        </div>
    </div>
}

function AdminListProjectsTable({ filters }: { filters: FiltersSchemaType }) {
    const {
        data,
        error,
        isLoading,
        mutate,
    } = useSWR(['/admin/projects', filters], (key) => {
        let query = key[1];
        return adminListProjects(query);
    });

    if (isLoading) {
        return <span>Loading...</span>
    }

    if (error) {
        return <AppErrorMessage error={error} />
    }

    return <div className="flex flex-col gap-3">
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>
                        ID
                    </TableHead>
                    <TableHead>
                        Title
                    </TableHead>
                    <TableHead>
                        Status
                    </TableHead>
                    <TableHead>
                        Payment Status
                    </TableHead>
                    <TableHead>
                        Dealer
                    </TableHead>
                    <TableHead>
                        Company
                    </TableHead>
                    <TableHead>
                        Price
                    </TableHead>
                    <TableHead>
                        Remaining Amount
                    </TableHead>
                    <TableHead>
                        Created At
                    </TableHead>
                    <TableHead>
                        Actions
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    data?.data.map(project => {
                        const canPay = canAddPayment(project);

                        return <TableRow key={project.id}>
                            <TableCell>
                                {project.id}
                            </TableCell>
                            <TableCell>
                                {project.title}
                            </TableCell>
                            <TableCell>
                                <ProjectStatusWithReason project={project} />
                            </TableCell>
                            <TableCell>
                                <PaymentStatusComponent project={project} />
                            </TableCell>
                            <TableCell>
                                {project.dealer?.businessName}
                            </TableCell>
                            <TableCell>
                                {project.companyName}
                            </TableCell>
                            <TableCell>
                                {formatMoneyStr(project.price, 0)}
                            </TableCell>
                            <TableCell>
                                {formatMoneyStr(project.remainingAmount, 0)}
                            </TableCell>
                            <TableCell>
                                {formatDate(project.createdAt)}
                            </TableCell>
                            <TableCell>
                                <div className="flex flex-wrap gap-2">
                                    <Link
                                        className={buttonVariants({ variant: 'outline' })}
                                        to={`${project.id}`}>
                                        View
                                    </Link>
                                    <ChangeProjectStatusButton
                                        projectId={project.id}
                                        variant={'outline'}
                                        onDone={() => {
                                            mutate();
                                        }}
                                    />
                                    {
                                        canPay && (
                                            <AddPaymentButton
                                                projectId={project.id}
                                                variant={'outline'}
                                                onDone={() => {
                                                    mutate();
                                                }}
                                            />
                                        )
                                    }
                                </div>
                            </TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
        <div className="flex justify-end">
            <TablePagination
                page={filters.page}
                perPage={filters.perPage}
                total={data?.total || 0}
            />
        </div>
    </div>
}

function AdminListProjectsPageFilters({ filters }: { filters: FiltersSchemaType }) {
    const [_, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const form = useForm<FiltersSchemaType>({
        resolver: zodResolver(filtersSchema),
        defaultValues: filters,
    });

    const handleSubmit: SubmitHandler<FiltersSchemaType> = (values) => {
        // values.prop could be undefined
        // but setSearchParams does not work with undefined
        setSearchParams({
            title: values.title || '',
            status: values.status || '',
            dealerId: values.dealerId || '',
            paymentStatus: values.paymentStatus || '',
        } as Record<string, string | string[]>);
    };

    const handleReset: React.EventHandler<React.MouseEvent> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSearchParams({});
        navigate(0);
    };

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex flex-col gap-8">
                <div>
                    <FormField
                        control={form.control}
                        name='status'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <RadioGroup
                                        onValueChange={field.onChange}
                                        defaultValue={field.value || ''}
                                        className="cursor-pointer flex flex-wrap gap-4"
                                        orientation="horizontal"
                                    >
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value="" id="all" />
                                            <Label htmlFor="all">All</Label>
                                        </div>
                                        {
                                            [
                                                ProjectStatus.InProgress,
                                                ProjectStatus.Submitted,
                                                ProjectStatus.Approved,
                                                ProjectStatus.Rejected,
                                                ProjectStatus.RequiresResubmission,
                                            ].map(status => (
                                                <div className="flex items-center space-x-2" key={status}>
                                                    <RadioGroupItem value={status} id={status} />
                                                    <Label htmlFor={status}>
                                                        {projectsStatusLabels[status]}
                                                    </Label>
                                                </div>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="flex justify-between items-end flex-wrap gap-4">
                    <div>
                        <div className="flex flex-wrap gap-2">
                            <FormField
                                control={form.control}
                                name='dealerId'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Dealer</FormLabel>
                                        <FormControl>
                                            <DealerSelectInput
                                                className="w-52"
                                                value={field.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="paymentStatus"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Payment Status</FormLabel>
                                        <FormControl>
                                            <Select
                                                defaultValue={field.value}
                                                onValueChange={field.onChange}>
                                                <FormControl>
                                                    <SelectTrigger
                                                        
                                                        className="min-w-[200px]" >
                                                        <SelectValue placeholder="" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {Object.entries(projectPaymentStatusLabels).map(([value, label]) => (
                                                        <SelectItem value={value} key={value}>
                                                            {label}
                                                        </SelectItem>
                                                    ))}
                                            </SelectContent>
                                        </Select>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='title'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Project Title</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Title"
                                                {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <Button type="submit" size='sm'>Search</Button>
                        <Button
                            onClick={handleReset}
                            variant='outline'
                            size='sm'
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </Form>
}