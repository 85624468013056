import { projectPaymentStatusColors, projectPaymentStatusLabels } from "@/common/enums";
import { ProjectBasic } from "../api";

export function PaymentStatusComponent({ project }: {
    project: ProjectBasic
}) {
    return <div className="flex items-center gap-1">
        <div className="items-center flex gap-2">
            <span className="w-4 h-4 flex-none inline-block rounded-full" style={{ backgroundColor: projectPaymentStatusColors[project.paymentStatus] }}></span>
            {projectPaymentStatusLabels[project.paymentStatus]}
        </div>
    </div>
}