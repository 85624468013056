import { Button, buttonVariants } from "@/common/components/ui/button";
import { Checkbox } from "@/common/components/ui/checkbox";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from "@/common/components/ui/dialog";
import { CheckCircle2, Loader2Icon } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { dealerAcceptProjectRequests } from "../api";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";


export const AcceptRequestPopup = ({
    requestId, open, onOpenChange, onDone,
}: {
    requestId: number;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onDone: () => void;
}) => {

    const [agree, setAgree] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);
    const [success, setSuccess] = useState(false);
    const [projectId, setProjectId] = useState<number | null>(null);

    const handleSubmit: React.MouseEventHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            setError(null);
            setSubmitting(true);
            const { projectId } = await dealerAcceptProjectRequests(requestId);
            setProjectId(projectId);
            setSuccess(true);
            onDone();
        } catch (e) {
            setError(e);
        } finally {
            setSubmitting(false);
        }
    };

    return <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent>
            {!success && (
                <>
                    <DialogHeader>
                        <DialogTitle>Accept Project Request?</DialogTitle>
                    </DialogHeader>
                    <div className="my-4">
                        {
                            !!error && (
                                <div className="mb-4">
                                    <AppErrorMessage error={error}/>
                                </div>
                            )
                        }
                        <div className="items-top flex space-x-2">
                            <Checkbox
                                id="agreeTerms"
                                checked={agree}
                                onCheckedChange={(c) => setAgree(c === true)} />
                            <div className="grid gap-1.5 leading-none">
                                <label
                                    htmlFor="agreeTerms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Accept the project request
                                </label>
                                <p className="text-sm text-muted-foreground">
                                    You have carefully read specs and the terms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        <div className="flex gap-2">
                            <Button
                                disabled={!agree || submitting}
                                variant={'success'}
                                onClick={handleSubmit}
                            >
                                {submitting && (
                                    <Loader2Icon className="w-4 mr-1 animate-spin" />
                                )}
                                Agree
                            </Button>
                            <Button
                                variant={'secondary'}
                                onClick={() => onOpenChange(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </DialogFooter>
                </>
            )}
            {success && (
                <>
                    <div className="my-4">
                        <div className="flex flex-col gap-2 items-center">
                            <CheckCircle2
                                className="text-green-500 w-32 h-32" />
                            <span className="text-2xl">
                                Project Accepted
                            </span>
                            <Link
                                to={`/app/dealer/projects/${projectId}`}
                                className={buttonVariants({ variant: 'success', className: 'mt-4' })}>
                                View Project Details
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </DialogContent>
    </Dialog>;
};
