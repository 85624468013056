import useSWR from "swr";
import { DealerApplicationMultistepForm } from "../components/dealer-application/dealer-application-multistep-form";
import { getDealer } from "../api/get-dealer";
import { useAuthUser } from "../../auth/hooks/use-auth-user";
import { DealerApplicationStatus, UserRole } from "@/common/enums";
import { Navigate } from "react-router-dom";
import { StepContainer } from "../components/dealer-application/step-container";
import { StepSkeleton } from "../components/dealer-application/step-skleton";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { useState } from "react";
import { DealerApplicationRejected } from "../components/dealer-application/dealer-application-rejected";
import { DealerApplicationApproved } from "../components/dealer-application/dealer-application-approved";
import { DealerApplicationInReview } from "../components/dealer-application/dealer-application-in-review";
import { StepLayout } from "../components/dealer-application/step-layout";

export function DealerApplicationPage() {
    const { user, initialized } = useAuthUser();
    const [ reapplying, setReapplying ] = useState(false);

    const {
        data : dealer,
        error,
        isLoading,    
    } = useSWR('/dealer/for-form-page', getDealer);

    if (!initialized) {
        return null;
    }

    if (user?.role !== UserRole.Dealer) {
        return <Navigate to='/app' />
    }

    if (isLoading) {
        return <StepContainer>
            <StepSkeleton />
        </StepContainer>
    }

    if (error) {
        return <StepContainer>
            <AppErrorMessage error={error} />
        </StepContainer>
    }


    if (dealer?.applicationStatus === DealerApplicationStatus.Rejected && !reapplying) {
        return <StepLayout>
            <StepContainer>
                <DealerApplicationRejected onReapply={() => setReapplying(true)}/>
            </StepContainer>
        </StepLayout>
    }
    
    if (dealer?.applicationStatus === DealerApplicationStatus.Approved) {
        return <StepLayout>
            <StepContainer>
                <DealerApplicationApproved />
            </StepContainer>
        </StepLayout>
    }

    if (dealer?.applicationStatus === DealerApplicationStatus.Submitted) {
        return <StepLayout>
            <StepContainer>
                <DealerApplicationInReview />
            </StepContainer>
        </StepLayout>
    }

    return <StepLayout>
        <DealerApplicationMultistepForm />
    </StepLayout>
}