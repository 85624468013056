import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { AuthLayout } from "./auth/layout/auth-layout";
import { LoginPage } from "./auth/pages/login";
import { DashboardPage } from "./dashboard/pages/dashboard-page";
import { DealerApplicationPage } from "./dealer/pages/dealer-application";
import { Protected } from "./auth/components/protected";
import { AppLayout } from "./app/layout/app-layout";
import { SignupPage } from "./auth/pages/signup";
import { DealersListPage } from "./dealers/pages/dealers-list-page";
import { DealerDetailPage } from "./dealers/pages/dealer-detail-page";
import { ForgotPassword } from "./auth/pages/forgot-password";
import { ResetPassword } from "./auth/pages/reset-password";
import { UserRole } from "./common/enums";
import { AdminCreateProjectRequestPage } from "./project-requests/pages/admin-create-project-request-page";
import { AdminListProjectRequestsPage } from "./project-requests/pages/admin-list-project-requests-page";
import { AdminEditProjectRequestPage } from "./project-requests/pages/admin-edit-project-request-page";
import { DealerListProjectRequestsPage } from "./project-requests/pages/dealer-list-project-requests-page";
import { DealerViewProjectRequestPage } from "./project-requests/pages/dealer-view-project-request-page";
import { AdminListProjectsPage } from "./projects/pages/admin-list-projects-page";
import { DealerListProjectsPage } from "./projects/pages/dealer-list-projects-page";
import { AdminViewProjectPage } from "./projects/pages/admin-view-project-page";
import { DealerViewProjectPage } from "./projects/pages/dealer-view-project-page";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to='/app' />
    },
    {
        path: '/app',
        element: <AppLayout />,
        children: [
            { path: '', element: <DashboardPage /> },
            {
                path: 'admin',
                element: (
                    <Protected roles={[UserRole.Admin]}>
                        <Outlet />
                    </Protected>
                ),
                children: [
                    { path: 'dealers', element: <DealersListPage /> },
                    { path: 'dealers/:dealerId', element: <DealerDetailPage /> },
                    {
                        path: 'project-requests',
                        element: <AdminListProjectRequestsPage />
                    },
                    {
                        path: 'project-requests/create',
                        element: <AdminCreateProjectRequestPage />
                    },
                    {
                        path: 'project-requests/:id/edit',
                        element: <AdminEditProjectRequestPage />
                    },
                    {
                        path: 'projects',
                        element: <AdminListProjectsPage />
                    },
                    {
                        path: 'projects/:id',
                        element: <AdminViewProjectPage />
                    },
                ],
            },
            {
                path: 'dealer',
                element: (
                    <Protected roles={[ UserRole.Dealer ]}>
                        <Outlet/>
                    </Protected>
                ),
                children: [
                    {
                        path: 'project-requests',
                        element: <DealerListProjectRequestsPage />
                    },
                    {
                        path: 'project-requests/:id',
                        element: <DealerViewProjectRequestPage />
                    },
                    {
                        path: 'projects',
                        element: <DealerListProjectsPage />
                    },
                    {
                        path: 'projects/:id',
                        element: <DealerViewProjectPage />
                    },
                ],
            }
        ]
    },
    {
        path: '/dealer-application',
        element: <Protected>
            <DealerApplicationPage />
        </Protected>
    },
    {
        path: '/auth',
        element: <AuthLayout />,
        children: [
            { path: 'login', element: <LoginPage /> },
            { path: 'signup', element: <SignupPage /> },
            { path: 'forgot-password', element: <ForgotPassword /> },
            { path: 'reset-password/:token', element: <ResetPassword /> },
        ]
    }
]);