import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { Textarea } from "@/common/components/ui/textarea";
import { asOptionalString } from "@/common/utils/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { unknown, z } from "zod";

export const formSchema = z.object({
    amount: z.string().refine((val) => {
        let num = parseFloat(val);
        return !isNaN(num) && num > 0;
    }, {
        message: 'positive number'
    }),
    notes: asOptionalString(z.string()),
});

export type FormSchemaType = z.infer<typeof formSchema>;

export const AddPaymentForm = ({
    onSubmit
}: {
    onSubmit: (data: FormSchemaType) => Promise<void>
}) => {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const form = useForm<FormSchemaType>({
        resolver: zodResolver(formSchema),
    })

    const handleSubmit = async (data: FormSchemaType) => {
        try {

            setSubmitting(true);
            setError(null);
            await onSubmit(data);

        } catch(e) {
            
            setError(e);

        } finally {

            setSubmitting(false);

        }
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <AppErrorMessage error={error} />
            <div className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Amount</FormLabel>
                            <FormControl>
                                <Input
                                    type="number"
                                    { ...field }
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="notes"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Notes</FormLabel>
                            <FormControl>
                                <Textarea
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="justify-end flex flex-end gap-2">
                    <Button disabled={submitting} type="submit">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    </Form>
}