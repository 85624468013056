import { Card, CardContent, CardHeader, CardTitle } from "@/common/components/ui/card";
import { InfoContent, InfoTile, InfoTitle } from "@/common/components/ui/info-tile";
import { dealerApplicationStatusLabels } from "@/common/enums";
import { DealerConfidentialWithRelated } from "@/dealer/entities/dealer";
import { DealerActions } from "./dealer-actions";
import { Address } from "@/address/components/address";
import { Link } from "react-router-dom";
import { buttonVariants } from "@/common/components/ui/button";
import { DealerApplicationStatusComponent } from "./dealer-application-status-component";

export function DealerInfoCard({ dealer, className }: { dealer: DealerConfidentialWithRelated; className?: string; }) {
    return <Card className={className}>
        <CardHeader className="flex flex-col lg:flex-row gap-4 lg:justify-between">
            <CardTitle>Basic Info</CardTitle>
            <div className="flex gap-2">
                <Link 
                    className={buttonVariants({ variant: 'outline' })}
                    to={`/app/admin/projects?dealerId=${dealer.id}`}
                >
                    Projects
                </Link>
                <Link 
                    className={buttonVariants({ variant: 'outline' })}
                    to={`/app/admin/project-requests?dealerId=${dealer.id}`}
                >
                    Project Requests
                </Link>
            </div>
        </CardHeader>
        <CardContent>
            <div className="grid grid-cols-6 gap-4">
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Company
                        </InfoTitle>
                        <InfoContent>
                            {dealer.businessName}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Application Status
                        </InfoTitle>
                        <InfoContent>
                            <DealerApplicationStatusComponent
                                dealer={dealer}
                            />
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Mobile Phone
                        </InfoTitle>
                        <InfoContent>
                            {dealer.mobilePhone ? (
                                <a href={`tel:${dealer?.mobilePhone}`}>
                                    {dealer?.mobilePhone}
                                </a>
                            ) : '-'}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Office Phone
                        </InfoTitle>
                        <InfoContent>
                            {dealer.officePhone ? (
                                <a href={`tel:${dealer?.officePhone}`}>
                                    {dealer?.officePhone}
                                </a>
                            ) : '-'}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Employee Count
                        </InfoTitle>
                        <InfoContent>
                            {dealer.employeesCount || '-'}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Years Of Experience
                        </InfoTitle>
                        <InfoContent>
                            {dealer.yearsOfExperience || '-'}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Representative
                        </InfoTitle>
                        <InfoContent>
                            {dealer?.representativeName || '-'}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
                <GridItem>
                    <InfoTile>
                        <InfoTitle>
                            Address
                        </InfoTitle>
                        <InfoContent>
                            {!!dealer?.address && <Address address={dealer?.address} />}
                        </InfoContent>
                    </InfoTile>
                </GridItem>
            </div>
            <div className="w-full mt-4">
                <InfoTile>
                    <InfoTitle>
                        Expertise Summary
                    </InfoTitle>
                    <InfoContent>
                        {dealer?.expertiseSummary || '-'}
                    </InfoContent>
                </InfoTile>
            </div>
            <div className="mt-4">
                <DealerActions dealer={dealer}/>
            </div>
        </CardContent>
    </Card>
}

export function GridItem({
    children
}: React.HtmlHTMLAttributes<HTMLElement>) {
    return <div className="col-span-2 lg:col-span-3">
        {children}
    </div>
}