import axios from '../../axios/refresh-instance';
import getStore from '../store';

export type RefreshTokenResponseDto = {
    accessToken: string
    accessTokenExpiryTs: number
}

export async function refreshToken() {
    const refreshToken = getStore().getTokens()!.refreshToken || '';

    // refresh token before proceeding
    const { data } = await axios.post<RefreshTokenResponseDto>('/auth/refresh-token', {
        refreshToken: refreshToken
    }, {
        skipAuth: true,
    });

    return data;
}