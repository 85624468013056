export enum Site {
    one800='one800',
    metal='metal',
    wholesale='wholesale'
}

export const CURRENT_SITE = process.env.REACT_APP_SITE as Site;

export const LOGO_PATH = {
    [Site.one800]: '/logo_one800.png',
    [Site.metal]: '/logo_metal.png',
    [Site.wholesale]: '/logo_wholesale.png'
}[CURRENT_SITE]