import { addLicense, editLicense, removeLicense } from "../api/licenses";
import { LicenseBasic } from "../entities/license";
import { LicensesFormSchemaType } from "./dealer-licenses-form";

function diffLicenses(
    oldLicenses: LicenseBasic[],
    newLicenses: LicensesFormSchemaType['licenses'],
) {
    const newLicensesIds = newLicenses.map(l => l.id);

    const addedLicenses = newLicenses.filter(l => l.id === undefined);

    const removedLicenses = oldLicenses.filter(l => !newLicensesIds.includes(l.id));

    const editedLicenses = newLicenses.filter(l => l.id !== undefined);

    return { addedLicenses, removedLicenses, editedLicenses };
}

/**
 * 
 * Separate handler decouples form from it's submission logic.
 * 
 */
export async function dealerLicensesFormHandler(
    oldLicenses: LicenseBasic[],
    data: LicensesFormSchemaType,
) {

    const newLicenses = data.licenses;

    const { addedLicenses, removedLicenses, editedLicenses } = diffLicenses(oldLicenses, newLicenses);
    
    const promises = [
        ...addedLicenses.map(l => addLicense({ ...l, countryCode: 'US' })),
        ...removedLicenses.map(l => removeLicense(l.id)),
        ...editedLicenses.map(l => editLicense(l.id!, { ...l, countryCode: 'US' } ))
    ];

    await Promise.all(promises);
}