import { StepProps } from "./dealer-application-multistep-form";
import { Button } from "@/common/components/ui/button";
import { ArrowLeft } from "lucide-react";

export function NextPrevButtons(props: StepProps & { submitting: boolean; }) {
    const { submitting, goPrev } = props;
    return <div className="flex justify-between mt-4">
        <div>
            {props.hasPrev && (
                <Button variant='ghost' onClick={goPrev}>
                    <ArrowLeft size={16} /> <span className="inline-block ml-1">Prev</span>
                </Button>
            )}
        </div>
        <Button size='lg' disabled={submitting}>
            { props.hasNext ? 'Next' : 'Submit' }
        </Button>
    </div>
}
