import { Button } from "@/common/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/common/components/ui/select";
import { dealerApplicationStatusLabels } from "@/common/enums";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiltersSchemaType, filtersSchema } from "../pages/dealers-list-page";

export function DealersListFilters({ filters }: { filters: FiltersSchemaType; }) {

    const [_, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const form = useForm<FiltersSchemaType>({
        resolver: zodResolver(filtersSchema),
        defaultValues: filters,
    });

    const handleSubmit: SubmitHandler<FiltersSchemaType> = (values) => {
        // values.prop could be undefined
        // but setSearchParams does not work with undefined
        setSearchParams({
            applicationStatus: (values.applicationStatus || ''),
            businessName: (values.businessName || ''),
            id: (values.id || ''),
        } as Record<string, string | string[]>);
    };

    const handleReset: React.EventHandler<React.MouseEvent> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSearchParams({});
        navigate(0);
    };

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex flex-col lg:flex-row gap-4">
                <div className="flex-1">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-3 lg:col-span-1">
                            <FormField
                                control={form.control}
                                name='businessName'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Dealer</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Dealer"
                                                {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                        </div>
                        <div className="col-span-3 lg:col-span-1">
                            <FormField
                                control={form.control}
                                name='applicationStatus'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Application Status</FormLabel>
                                        <Select
                                            defaultValue={field.value}
                                            onValueChange={field.onChange}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {Object.entries(dealerApplicationStatusLabels).map(([value, label]) => (
                                                    <SelectItem value={value} key={value}>
                                                        {label}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                        </div>
                        <div className="col-span-3 lg:col-span-1">
                            <FormField
                                control={form.control}
                                name='id'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>ID</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="ID"
                                                {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap gap-2 lg:mt-8">
                    <Button size='sm'>Search</Button>
                    <Button
                        onClick={handleReset}
                        variant='outline'
                        size='sm'
                    >
                        Reset
                    </Button>
                </div>
            </div>
        </form>
    </Form>
}
