import { useParams } from "react-router-dom";
import useSWR from "swr";
import { findById } from "../api";
import { TableSkleton } from "@/common/components/ui/table-skleton";
import { AppErrorMessage } from "@/common/components/ui/app-error-message";
import { Card, } from "@/common/components/ui/card";
import { LicensesCard } from "../components/licenses-card";
import { DealerInfoCard } from "../components/dealer-info-card";
import { DealerPastProjectsCard } from "../components/past-projects-card";

export function DealerDetailPage() {
    const { dealerId } = useParams();

    const {
        data: dealer,
        error,
        isLoading,
    } = useSWR(['/dealers', dealerId], async (key) => {
        const id = key[1];
        return findById(id);
    });

    if (isLoading) {
        return <Card className="p-2">
            <TableSkleton />
        </Card>
    }

    if (error) {
        return <Card className="p-2">
            <AppErrorMessage error={error} />
        </Card>
    }

    if (!dealer) {
        return <div>
            Dealer not found
        </div>
    }

    return <div className="flex flex-col gap-4">
        <DealerInfoCard dealer={dealer} />
        <LicensesCard dealer={dealer} />
        <DealerPastProjectsCard dealer={dealer} /> 
    </div>
}